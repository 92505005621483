import React, { useState, useEffect, createContext, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useIMSForm } from "../detail/IMSFormContext";
//import { trackIMSFormChanged } from '../../../PostHogFunction';

const upcomingAuctions = [
  {
    auction_id: 11129,
    auction: "240410",
    title: "SOLD! April 10 Vehicles and Equipment Auction",
    auction_date: "240410",
    workspace_label: "240410ve",
  },
  {
    auction_id: 11130,
    auction: "240411",
    title: "Thursday April 11 Construction Equipment Auction",
    auction_date: "240411",
    workspace_label: "240411const",
  },
  {
    auction_id: 11131,
    auction: "240416",
    title: "Tuesday April 16 Government Auction",
    auction_date: "240416",
    workspace_label: "240416govt",
  },
  {
    auction_id: 11132,
    auction: "240417",
    title: "Wednesday April 17 Ag Equipment Auction",
    auction_date: "240417",
    workspace_label: "240417ag",
  },
  {
    auction_id: 11152,
    auction: "240417A",
    title: "Wednesday April 17 T.A. Schifsky & Sons Inc. Retirement Auction",
    auction_date: "240417",
    workspace_label: "240417ta",
  },
  {
    auction_id: 11133,
    auction: "240418",
    title: "Thursday April 18 Truck and Trailer Auction",
    auction_date: "240418",
    workspace_label: "240418truck",
  },
  {
    auction_id: 11155,
    auction: "240423",
    title: "Tuesday April 23 Turner Bros. Inc. Retirement Auction",
    auction_date: "240423",
    workspace_label: "240423turner",
  },
  {
    auction_id: 11134,
    auction: "240424",
    title: "Wednesday April 24 Vehicles and Equipment Auction",
    auction_date: "240424",
    workspace_label: "240424ve",
  },
  {
    auction_id: 11135,
    auction: "240425",
    title: "Thursday April 25 Construction Equipment Auction",
    auction_date: "240425",
    workspace_label: "240425const",
  },
  {
    auction_id: 11136,
    auction: "240430",
    title: "Tuesday April 30 Government Auction",
    auction_date: "240430",
    workspace_label: "240430govt",
  },
  {
    auction_id: 11137,
    auction: "240501",
    title: "Wednesday May 01 Ag Equipment Auction",
    auction_date: "240501",
    workspace_label: "240501ag",
  },
  {
    auction_id: 11138,
    auction: "240502",
    title: "Thursday May 02 Truck and Trailer Auction",
    auction_date: "240502",
    workspace_label: "240502truck",
  },
  {
    auction_id: 11139,
    auction: "240508",
    title: "Wednesday May 08 Vehicles and Equipment Auction",
    auction_date: "240508",
    workspace_label: "240508ve",
  },
  {
    auction_id: 11140,
    auction: "240509",
    title: "Thursday May 09 Construction Equipment Auction",
    auction_date: "240509",
    workspace_label: "240509const",
  },
  {
    auction_id: 11141,
    auction: "240514",
    title: "Tuesday May 14 Government Auction",
    auction_date: "240514",
    workspace_label: "240514govt",
  },
  {
    auction_id: 11142,
    auction: "240515",
    title: "Wednesday May 15 Ag Equipment Auction",
    auction_date: "240515",
    workspace_label: "240515ag",
  },
  {
    auction_id: 11143,
    auction: "240516",
    title: "Thursday May 16 Truck and Trailer Auction",
    auction_date: "240516",
    workspace_label: "240516truck",
  },
  {
    auction_id: 11144,
    auction: "240522",
    title: "Wednesday May 22 Vehicles and Equipment Auction",
    auction_date: "240522",
    workspace_label: "240522ve",
  },
  {
    auction_id: 11145,
    auction: "240523",
    title: "Thursday May 23 Construction Equipment Auction",
    auction_date: "240523",
    workspace_label: "240523const",
  },
  {
    auction_id: 11146,
    auction: "240528",
    title: "Tuesday May 28 Government Auction",
    auction_date: "240528",
    workspace_label: "240528govt",
  },
  {
    auction_id: 11147,
    auction: "240529",
    title: "Wednesday May 29 Ag Equipment Auction",
    auction_date: "240529",
    workspace_label: "240529ag",
  },
  {
    auction_id: 11148,
    auction: "240530",
    title: "Thursday May 30 Truck and Trailer Auction",
    auction_date: "240530",
    workspace_label: "240530truck",
  },
  {
    auction_id: 11157,
    auction: "240605",
    title: "Wednesday June 05 Vehicles and Equipment Auction",
    auction_date: "240605",
    workspace_label: "240624ve",
  },
  {
    auction_id: 11158,
    auction: "240606",
    title: "Thursday June 06 Construction Equipment Auction",
    auction_date: "240606",
    workspace_label: "240606const",
  },
  {
    auction_id: 11159,
    auction: "240611",
    title: "Tuesday June 11 Government Auction",
    auction_date: "240611",
    workspace_label: "240611govt",
  },
  {
    auction_id: 11160,
    auction: "240612",
    title: "Wednesday June 12 Ag Equipment Auction",
    auction_date: "240612",
    workspace_label: "240612ag",
  },
  {
    auction_id: 11161,
    auction: "240613",
    title: "Thursday June 13 Truck and Trailer Auction",
    auction_date: "240613",
    workspace_label: "240613truck",
  },
  {
    auction_id: 11162,
    auction: "240619",
    title: "Wednesday June 19 Vehicles and Equipment Auction",
    auction_date: "240619",
    workspace_label: "240619ve",
  },
  {
    auction_id: 11163,
    auction: "240620",
    title: "Thursday June 20 Construction Equipment Auction",
    auction_date: "240620",
    workspace_label: "240620const",
  },
  {
    auction_id: 11164,
    auction: "240625",
    title: "Tuesday June 25 Government Auction",
    auction_date: "240625",
    workspace_label: "240625govt",
  },
  {
    auction_id: 11165,
    auction: "240626",
    title: "Wednesday June 26 Ag Equipment Auction",
    auction_date: "240626",
    workspace_label: "240626ag",
  },
  {
    auction_id: 11166,
    auction: "240627",
    title: "Thursday June 27 Truck and Trailer Auction",
    auction_date: "240627",
    workspace_label: "240627truck",
  },
];

// Step 1: Create a context
export const IMSContext = createContext();

IMSformCategtorySelect.propTypes = {
  customerNumber: PropTypes.string,
};

function IMSformCategtorySelect({ customerNumber, evalItem }) {
  // Step 2: Use state from the context
  const {
    categoriesData,
    setCategoriesData,
    industriesData,
    setIndustriesData,
    selectedIndustry,
    setSelectedIndustry,
    selectedFamily,
    setSelectedFamily,
    selectedCategory,
    setSelectedCategory,
    setSelectedOpportunity,
    jobCodeData,
    setJobCodeData,
  } = useContext(IMSContext);

  const { imsForm, setImsForm } = useIMSForm();

  useEffect(() => {
    console.log("TESTING REAL FORM ====================>>>>>>>>", imsForm);
  }, [selectedCategory]);

  const imsFormData = async () => {
    try {
      const response = await fetch(
        "https://ims-api.purplewave.com/v1/industries?published=1"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setIndustriesData(data);

      const allCategories = data.flatMap((industry) =>
        industry.Families.flatMap((family) =>
          family.Categories.map((category) => category.category)
        )
      );
      setCategoriesData(allCategories);

      console.log("ALL CATEGORIES", allCategories);
    } catch (error) {
      console.error("Error fetching industries:", error);
    }
  };

  useEffect(() => {
    imsFormData();
    //opportunityNumber();
    console.log("MY NUMBER =======+>>>>>>>", customerNumber);
  }, []);

  useEffect(() => {
    console.log("===========JCODE============", imsForm);
    if (imsForm?.item?.ims_forms_data?.job_code) {
      setJobCodeData(imsForm?.item?.ims_forms_data?.job_code);
    }
  }, [selectedCategory]);

  useEffect(() => {
    // Check if evalItem.item.ims_form_data.opportunity_id exists
    const opportunityId = imsForm?.item?.ims_forms_data?.opportunity_id
      ? imsForm?.item?.ims_forms_data?.opportunity_id
      : evalItem?.item?.ims_forms_data?.opportunity_id;
    // If opportunityId exists, set the form to opportunityId, else set it to null
    setSelectedOpportunity(opportunityId ? opportunityId : null);
  }, [evalItem, imsForm]);

  const industryOptions = industriesData.map((industry) => ({
    value: industry,
    label: industry.industry,
  }));

  const familyOptions = selectedIndustry
    ? selectedIndustry.Families.map((family) => ({
        value: family,
        label: family.family,
      }))
    : [];

  const categoryOptions = selectedFamily
    ? selectedFamily.Categories.map((category) => ({
        value: category,
        label: category.category,
      }))
    : [];

  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  const handleFamilyChange = (event) => {
    const value = event.target.value;
    setSelectedFamily(value);
    setSelectedCategory(null);
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const handleAutocompleteChange = (_, newValue) => {
    const selectedCategory = categoriesData.find(
      (category) => category === newValue
    );
    console.log("Selected Category:", selectedCategory); // Debug log

    if (selectedCategory) {
      // Find the corresponding industry, family, and category
      for (const industry of industriesData) {
        for (const family of industry.Families) {
          for (const category of family.Categories) {
            if (category.category === newValue) {
              console.log("Select Category in search:", selectedCategory);
              setSelectedIndustry(industry);
              setSelectedFamily(family);
              setSelectedCategory(category);
              return;
            }
          }
        }
      }
    }

    // If no matching industry, family, and category found, reset the selections
    setSelectedIndustry(null);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  useEffect(() => {
    //console.log("RELOAD=======RELOAD==========RELOAD", imsForm, evalItem, selectedCategory);

    const selectedCategoryFromIMSform = imsForm?.item?.ims_forms_data.category;
    const selectedCategoryFromEvalItem =
      evalItem?.item?.ims_forms_data.category;

    console.log(
      "Selected category from IMS form:",
      selectedCategoryFromIMSform
    );
    console.log(
      "Selected category from Eval item:",
      selectedCategoryFromEvalItem
    );

    if (selectedCategoryFromIMSform) {
      const newValue = imsForm?.item.ims_forms_data.category;
      handleAutocompleteChange(null, newValue);
    } else if (selectedCategoryFromEvalItem) {
      const newValue = evalItem?.item?.ims_forms_data.category;
      handleAutocompleteChange(null, newValue);
    } else if (!selectedCategory) {
      console.log("Selected category is null. Skipping effect.");
    }
  }, [imsForm, evalItem, categoriesData]);

  return (
    <div>
      <Autocomplete
        disablePortal
        id="category-search"
        options={categoriesData}
        value={selectedCategory ? selectedCategory.category : null}
        sx={{ width: "60%" }}
        onChange={(_, newValue) => handleAutocompleteChange(_, newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Search Category" />
        )}
      />
      <div style={{ margin: "12px 0" }}></div>
      {/* <FormControl fullWidth>
        <InputLabel shrink={!!selectedIndustry} htmlFor="industry-select">Select Industry</InputLabel>
        <Select
          value={selectedIndustry}
          onChange={handleIndustryChange}
          label="Select Industry"
          inputProps={{
            name: 'industry',
            id: 'industry-select',
          }}
          style={{ width: '60%', fontWeight: 'normal' }}
        >
          {industryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedIndustry}
          htmlFor="industry-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Optional: adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Industry
        </InputLabel>
        <Select
          value={selectedIndustry}
          onChange={handleIndustryChange}
          label="Select Industry"
          inputProps={{
            name: "industry",
            id: "industry-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {industryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>
      {/* <FormControl fullWidth>
        <InputLabel shrink={!!selectedFamily} htmlFor="family-select">Select Equipment Type</InputLabel>
        <Select
          value={selectedFamily}
          onChange={handleFamilyChange}
          label="Select Equipment Type"
          inputProps={{
            name: 'family',
            id: 'family-select',
          }}
          style={{ width: '60%', fontWeight: 'normal' }} // Set width to 60% and font weight to normal
        >
          {familyOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedFamily}
          htmlFor="family-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Equipment Type
        </InputLabel>
        <Select
          value={selectedFamily}
          onChange={handleFamilyChange}
          label="Select Equipment Type"
          inputProps={{
            name: "family",
            id: "family-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {familyOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>
      {/* <FormControl fullWidth>
        <InputLabel shrink={!!selectedCategory} htmlFor="category-select">Select Category</InputLabel>
        <Select
           value={selectedCategory}
          onChange={handleCategoryChange}
          label="Select Category"
          inputProps={{
            name: 'category',
            id: 'category-select',
          }}
          style={{ width: '60%', fontWeight: 'normal' }} // Set width to 60% and font weight to normal
        >
          {categoryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedCategory}
          htmlFor="category-select"
          style={{
            backgroundColor: "#ffffff",
            padding: "0 4px",
            zIndex: 1,
            fontWeight: "400 !important",
          }}
        >
          Select Category
        </InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Select Category"
          inputProps={{
            name: "category",
            id: "category-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
            fontWeight: "400 !important",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {categoryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ margin: "12px 0" }}></div>

      <div style={{ margin: "12px 0" }}></div>
      <FormControl fullWidth>
        <InputLabel shrink={!!jobCodeData} htmlFor="jobecode-select">
          Select Job Code
        </InputLabel>
        <Select
          value={jobCodeData}
          onChange={(event) => setJobCodeData(event.target.value)}
          label="Select Job Code"
          inputProps={{
            name: "jobcode",
            id: "jobcode-select",
          }}
          style={{ width: "60%", fontWeight: "normal" }}
        >
          {upcomingAuctions.map((jobcode) => (
            <MenuItem key={jobcode.auction_id} value={jobcode.workspace_label}>
              {jobcode.workspace_label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export const IMSProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState("");
  const [jobCodeData, setJobCodeData] = useState("");

  useEffect(() => {
    // Your useEffect logic without localStorage
  }, []);

  return (
    <IMSContext.Provider
      value={{
        categoriesData,
        setCategoriesData,
        industriesData,
        setIndustriesData,
        selectedIndustry,
        setSelectedIndustry,
        selectedFamily,
        setSelectedFamily,
        selectedCategory,
        setSelectedCategory,
        selectedOpportunity,
        setSelectedOpportunity,
        jobCodeData,
        setJobCodeData,
      }}
    >
      {children}
    </IMSContext.Provider>
  );
};

export default IMSformCategtorySelect;
