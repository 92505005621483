import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {isEqual, get} from 'lodash';
import React, {Component} from 'react';
import {EMAIL_SMALL_ICON, GET_SUBSCRIPTIONS} from '../Constants';
import Typography from '../fhg/components/Typography';
import {getUserData, withRequest, setSendEmails, setPromptEmailSubscription} from '../Utils/ServerUtil';
import {submitSendEmails} from '../Utils/SubmitUtil';

const TIME_INTERVAL = 750;

const styles = theme => ({
   root: {
      height: '100%',
   },
   outerFrame: {
      border: `1px solid ${theme.palette.brand.gray}`, //#5D788F
      borderRadius: 2,
      backgroundColor: theme.palette.environment.light.level0.base, //'#FFFFFF'
      maxWidth: 670,
   },
   innerFrame: {
      padding: theme.spacing(1.5, 3, 2),
      '& p': {
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            margin: '0 0',
         },
         'margin-block-start': 0,
         'margin-block-end': 0,
         '-webkit-margin-start': 0,
         '-webkit-margin-end': 0,
         '-webkit-margin-before': 0,
         '-webkit-margin-after': 0,
      },
   },
   sideBarStyle: {
      backgroundColor: theme.palette.brand.gray, //#5D788F
      width: 48,
   },
   iconStyle: {
      marginTop: theme.spacing(2),
   },
   checkboxStyle: {
      color: `${theme.palette.environment.light.divider} !important`,
      backgroundColor: `${theme.palette.environment.light.level0.base} !important`
   },
   checkedStyle: {
      color: `${theme.palette.button.standard.primary} !important`,
   },
   titleStyle: {
      paddingBottom: theme.spacing(1),
   },
   noteStyle: {
      paddingBottom: theme.spacing(2),
   },
   closeStyle: {
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(-2),
      backgroundColor: `${theme.palette.environment.light.level0.base} !important`
   },
});

/**
 * The email subscription card.
 */
class EmailSubscribeCard extends Component {

   constructor(props, context) {
      super(props, context);

      const sendEmails = get(props, 'data.send_emails');
      const promptEmailSubscription = get(props, 'data.prompt_email_subscription');
      this.state = {
         isSubscribe: sendEmails,
         isOpen: promptEmailSubscription,
      };
      setSendEmails(sendEmails);
      setPromptEmailSubscription(promptEmailSubscription);
   }

   componentDidMount() {
      this.interval = setInterval(() => {
         const sendEmails = getUserData().sendEmails;
         if (sendEmails !== this.state.isSubscribe) {
            this.setState({isSubscribe: sendEmails})
         }
      }, TIME_INTERVAL)
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (!isEqual(this.props.data, prevProps.data)) {
         this.setState({
            isSubscribe: get(this.props, 'data.send_emails'),
            isOpen: get(this.props, 'data.prompt_email_subscription'),
         })
      }
   }

   componentWillUnmount() {
      clearInterval(this.interval);
   }

   handleCheckboxChange = async ({target}) => {
      this.setState({[target.name]: target.checked}, async () => {
         submitSendEmails(target.checked);
      });
   };

   handleClose = () => {
      const {onClose} = this.props;
      this.setState({isOpen: false}, onClose);
   };

   render() {
      const {classes, className} = this.props;

      return (
         <Collapse in={this.state.isOpen}>
            <Card
               className={`${className} ${classes.outerFrame}`}
               aria-labelledby='alert-dialog-slide-title'
               aria-describedby='alert-dialog-slide-description'
            >
               <Grid container direction={'row'} className={classes.root}>
                  <Grid item container className={classes.sideBarStyle} justifyContent={'center'}>
                     <Grid item>
                        <img src={EMAIL_SMALL_ICON} alt='' className={classes.iconStyle}/>
                     </Grid>
                  </Grid>
                  <Grid item className={classes.innerFrame}>
                     <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                           <Typography className={`contrast-text ${classes.titleStyle}`}
                                       id={'emailSubscribe.subscribe.title'}/>
                        </Grid>
                        <Grid item>
                           <IconButton key='close' aria-label='Close' color='inherit' className={classes.closeStyle}
                                       onClick={this.handleClose}>
                              <CloseIcon/>
                           </IconButton>
                        </Grid>
                     </Grid>
                     <Typography className={`default-text-small ${classes.noteStyle}`}
                                 id={'emailSubscribe.subscribe.note'}
                                 hasLineBreaks/>
                     <FormControlLabel
                        control={
                           <Checkbox
                              name={'isSubscribe'}
                              color={'primary'}
                              classes={{checked: classes.checkedStyle}}
                              className={classes.checkboxStyle}
                              checked={this.state.isSubscribe}
                              onChange={this.handleCheckboxChange}
                              disableRipple
                              value='isSubscribe'
                           />
                        }
                        label={<Typography className={`default-text-small`} id={'emailSubscribe.subscribe.checkbox'}/>}
                     />
                  </Grid>
               </Grid>
            </Card>
         </Collapse>
      );
   }
}

export default withRequest({
   uri: GET_SUBSCRIPTIONS,
   errorMessageId: 'subscribe.fetch.error',
   showProgress: false,
   showOnLoad: false,
})(withStyles(styles)(EmailSubscribeCard));
