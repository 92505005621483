import {Link} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AddCircleOutline, Clear} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import anchorme from 'anchorme';
import classNames from 'classnames';
import {findIndex} from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import React, {useState, useMemo, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import 'react-table/react-table.css';
import {EQUIPMENT_DETAIL_PROPERTY_WIDTH, DELETE_LINK} from '../../../Constants';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import Typography from '../../../fhg/components/Typography';
import useKeyDown from '../../../fhg/useKeyDown';
import {formatMessage, removeOne} from '../../../fhg/utils/Utils';
import {getUserData, isAdminOnly} from '../../../Utils/ServerUtil';
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer';
import ErrorSnackbar from '../components/ErrorSnackbar';
import {valuationTypeOptions} from '../components/ValuationTypeMenu';
import {getEquipmentDescription} from '../EquipmentUtil';
import EditLinkDialog from '../list/EditLinkDialog';
import {isMultipleOrganization } from '../../../Utils/ServerUtil';
import { Auth } from 'aws-amplify';
//import { useIMSForm } from './IMSFormContext';

/**
 * Styles for the asset detail.
 */
const useStyles = makeStyles(theme => ({
   root: {
      flex: '1 1 auto',
      overflow: 'hidden',
      backgroundColor: theme.palette.environment.light.level1.base,
      [theme.breakpoints.up('md')]: {
         overflow: 'hidden',
      },
      [theme.breakpoints.down('sm')]: {
         display: 'block',
      },
      position: 'relative',
   },
   backgroundStyle: {
      backgroundColor: theme.palette.environment.light.level0.base,
   },
   backgroundStyle1: {
      backgroundColor: theme.palette.environment.light.level1.base,
   },
   innerGrid: {
      flex: '1 1 auto',
      overflow: 'hidden',
      padding: theme.spacing(1, 0, 2),
      //height: '100% !important', doesn't work on Safari. Following replaces.
      position: 'absolute',
      top: 0,
      bottom: 0,
   },
   propertyGrid: {
      flex: '1 1 auto',
      overflow: 'auto',
      position: 'relative',
   },
   description: {
      marginLeft: 0,
      [theme.breakpoints.down('xs')]: {
         fontSize: '20px  !important',
         lineHeight: '24px  !important',
      },
   },
   propertiesStyle: {
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         height: `44px !important`,
         display: '-ms-flexbox',
         minHeight: 44,
      },
      '@supports not (-ms-high-contrast: none)': {
         display: 'flex',
      },
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.environment.light.level1.accent}`,
      padding: `${theme.spacing(1)}px 0`,
   },
   smallProperty: {
      margin: `0 0 ${theme.spacing(4)}px 0`,
      padding: '0 0px',
   },
   smallPropertyBottom: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: '0 0px',
   },
   smallPropertyTop: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px 0`,
      padding: '0 0px',
   },
   topProperty: {
      display: 'flex',
      padding: theme.spacing(0, 2, 0, 0),
      flex: '1 1 auto',
      minHeight: 'min-content',
   },
   titleStyle: {
      composes: 'nonessential-title-caps',
   },
   propertyNumberStyle: {
      composes: 'numbers-small',
      color: theme.palette.text.secondary,
      letterSpacing: `${theme.size.letterspacing.numbers}px`,
   },
   baseLargePropertyStyle: {
      composes: 'text',
      color: `${theme.palette.text.secondary} !important`,
   },
   propertyLocationStyle: {
      composes: '$baseLargePropertyStyle',
   },
   updatedByStyle: {
      composes: 'subtle-text-small',
   },
   notesStyle: {
      composes: 'default-text-small',
      overflow: 'hidden',
      width: '100%',
   },
   notesInnerStyle: {
      composes: 'default-text-small',
      whiteSpace: 'pre-line',
   },
   featuresInnerStyle: {
      composes: 'default-text-small',
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
   },
   largePropertyStyle: {
      composes: '$baseLargePropertyStyle',
   },
   titleMargin: {
      marginTop: theme.spacing(1),
   },
   divider: {
      marginTop: theme.spacing(4),
      backgroundColor: theme.palette.environment.light.level1.accent,
   },
   gridItemStyle: {
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         flex: '0 0 auto'
      }
   },
}), 'AssetDetailStyles');

AssetDetail.propTypes = {
   evalItem: PropTypes.object.isRequired,    // The evaluation item.
   showEquipmentTitle: PropTypes.bool,       // Indicates if the equipment title should be shown.
   showLocationTitle: PropTypes.bool,        // Indicates if the location label should be shown.
   showLocation: PropTypes.bool,             // Indicates if the location should be shown.
   showEditButton: PropTypes.bool,           // Indicates if the Edit button should be shown.
   showSummaryTitle: PropTypes.bool,         // Indicates if the "Summary" title should be shown.
   width: PropTypes.number,                  // The width of the panel.
};

AssetDetail.defaultProps = {
   showEquipmentTitle: true,
   showLocationTitle: true,
   showLocation: true,
   showEditButton: true,
   showSummaryTitle: false,
   width: EQUIPMENT_DETAIL_PROPERTY_WIDTH,
};

////const { imsForm, setImsForm } = useIMSForm();

/**
 * Component to show the asset details.
 */
function AssetDetail({
   intl, evalItem, showEquipmentTitle, showLocationTitle, showEditButton, showBackground, showSummaryTitle, width,
   showLocation, onClose,
}) {
   const classes = useStyles();
   useKeyDown(onClose);

   const [showError, setShowError] = useState(false);
   const [editLink, setEditLink] = useState();
   const [isNewLink, setIsNewLink] = useState(false);
   const [imsFormDetails, setImsFormDetails] = useState({})

   const [submitLink, {error}] = useLazyRequestForServer();
   const [links, setLinks] = useState([]);


   useEffect(()=> {
      const fetchIMSData = async () => {
          let jwtToken;
          let tokenRefreshError = false;
      
          try {
            const session = await Auth.currentSession();
            jwtToken = session.idToken.jwtToken;
          } catch (e) {
            try {
              const session = await Auth.currentSession();
              jwtToken = session.idToken.jwtToken;
            } catch (e) {
              //console.log("Error refreshing token", e);
              tokenRefreshError = true;
              // Allow to go through without jwtToken for server requests not requiring an authenticated user.
            }
          }
      
          const headers = {
            authorization: jwtToken,
            accept: "application/json",
          };
          // This adds a header for selected organization_ID or _Key which is stored in local storage
          if (isAdminOnly() || isMultipleOrganization()) {
            const organization = localStorage["hw." + window.btoa("organization")];
            if (organization && window.atob(organization)) {
              headers["HW-Organization"] = window.atob(organization);
            }
          }
          const options = {
            method: "GET",
            headers: headers,
          };
      
          const url = `${process.env.REACT_APP_API_ENDPOINT}/items/${evalItem.item_id}`;
      
          // console.log("url====> ",url);
          try {
            const response = await fetch(url, options);
            if (response.ok) {
              const itemData = await response.json();
              console.log("RETURNED ITEM DETAILS ========>", itemData);
  
              setImsFormDetails(itemData)
  
            } else {
              // Handle the error if the response is not ok
              console.log("Request failed with status:", response.status);
              return null;
            }
          } catch (error) {
            // Handle any network or other errors
            console.error("Error:", error);
          }
        };
  
        fetchIMSData()
        
     }, [evalItem])
  

     useEffect(() => {
      if (!imsFormDetails?.ims_forms_data || Object.keys(imsFormDetails?.ims_forms_data).length === 0){
         setImsFormDetails(evalItem)
        }
      
     }, [evalItem, imsFormDetails ])


   useEffect(() => {
       if (!!evalItem.links && evalItem.links.length > 0) {
          setLinks([...evalItem.links]);
       }
   }, [evalItem.links]);

   /**
    * Show/Hide the error when it occurs or is removed.
    */
   useEffect(() => {
       setShowError(!!error);
   }, [error]);

   /**
    * Close the error snackbar.
    */
   const handleErrorClose = () => {
      setShowError(false);
   };

   /**
    * Get the person who updated the item.
    *
    * @param updatedBy The user who updated the item.
    * @return {string} The person who updated the item or 'You' if the current user updated the item.
    */
   const getUpdatedBy = (updatedBy) => {
      if (updatedBy) {
         return formatMessage(intl, 'equipmentDetail.updatedBy.text', 'by {name} ',
            {name: updatedBy === getUserData().name ? 'you' : updatedBy});
      } else {
         return '';
      }
   }

   /**
    * Get the time a user last updated the item.
    *
    * @param updated The last update time.
    * @return {string} The humanized description of the time.
    */
   const getUpdatedTime = (updated) => {
      return moment(updated).calendar(null, {
         sameDay: function (now) {
            const minutes = now.diff(this, 'minutes');
            if (minutes < 5) {
               return `[${formatMessage(intl, 'time.fewMinutes.text')}]`;
            } else if (minutes < 60) {
               return `[${formatMessage(intl, 'time.minutes.text', '{minutes} minutes ago', {minutes})}]`;
            } else if (minutes < 180) {
               return `[${formatMessage(intl, 'time.hours.text')}]`;
            } else {
               return `[${formatMessage(intl, 'time.today.text')}] h:mm`;
            }
         },
         lastDay: function (now) {
            return `[${formatMessage(intl, 'time.yesterday.text')}]`;
         },
         lastWeek: 'MMM D',
         sameElse: function (now) {
            if (this.year() === now.year()) {
               return 'MMM D';
            } else {
               return 'MMM D, YYYY';
            }
         }
      });
   }

   const handleLinkClose = () => {
      setEditLink(undefined);
      setIsNewLink(false);
   };

   const handleLinkSubmit = () => {
      setLinks([...evalItem.links]);
   };

   const deleteLink = (link) => async () => {
      try {
         await submitLink(DELETE_LINK.format({assetKey: evalItem.item_id, linkKey: link.key}), 'del');
         const index = findIndex(evalItem.links, {key: link.key});
         removeOne(evalItem.links, index);
         setLinks([...evalItem.links]);
      } catch (error) {
         //Intentionally ignored. Needed to avoid false error indicating an uncaught exception.
      }
   };

   const item = get(evalItem, 'item') || {};

   const meters = (typeof item.meters === 'string' ? JSON.parse(item.meters) : item.meters) || [];
   const equipmentLabel = useMemo(() => (showEquipmentTitle ? getEquipmentDescription(item) : ''),
      [item, showEquipmentTitle]);
   const notes = useMemo(() => {
      let notesDangerous = get(item, 'notes', '').trim();
      const temp = document.createElement('div');
      temp.innerHTML = notesDangerous;

      return anchorme({
         input: temp.textContent || temp.innerText,
         options: {
            attributes: {
               target: '_blank',
            }
         }
      });
   }, [item]);

   const hasConditionalProperty = (item.serial && item.serial !== 'unknown') ||
      (item.vin && item.vin !== 'unknown') || (item.personal_id && item.personal_id !== 'unknown') ||
      (evalItem.sticker_id && evalItem.sticker_id !== 'unknown') || (meters && meters.length > 0);
   return (
      <div className={`${classes.root} ${showBackground ? classes.backgroundStyle1 : classes.backgroundStyle}`}>
         {showError && (
            <ErrorSnackbar open={true} errorId={'link.delete.error'} values={error} enableRefresh={false}
                           onClose={handleErrorClose}/>
         )}
         <Grid container direction={'column'} spacing={0} className={classes.innerGrid} wrap={'nowrap'}>
            <Grid container direction={'column'} spacing={0} wrap={'nowrap'}
                  className={classes.propertyGrid}>
               <Grid container direction={'column'} className={classes.topProperty} style={{width: (width - 50)}}
                     spacing={0} wrap={'nowrap'}>
                  {showSummaryTitle && (
                     <Grid item style={{marginBottom: 16}}>
                        <Typography className={classNames(classes.description, 'summary')}>
                           Summary
                        </Typography>
                     </Grid>
                  )}
                  {showEquipmentTitle && (
                     <Grid item className={classes.gridItemStyle}>
                        <Typography className={classNames(classes.description, 'level-3-heading')}>
                           {equipmentLabel}
                        </Typography>
                     </Grid>
                  )}
                  {showLocation && (
                     <Grid container spacing={0} className={classes.gridItemStyle}>
                        {showLocationTitle && (
                           <Typography className={`${classes.titleStyle} ${classes.titleMargin}`}
                                       id={'equipmentList.location.title'}/>
                        )}
                        <Grid container className={`${classes.propertiesStyle} ${classes.gridItemStyle}`}
                              style={{border: 'none'}} spacing={0}>
                           <Typography className={classes.propertyLocationStyle}>
                              {item.location}
                           </Typography>
                        </Grid>
                     </Grid>
                  )}
                  <Grid container className={classes.gridItemStyle}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle} id={'valuationType.title'}/>
                     <Typography className={classes.updatedByStyle}
                                 id={valuationTypeOptions[item.valuation_type] || 'missing'}/>
                  </Grid>
                  <Grid container className={`${classes.smallPropertyTop} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle}
                                 id={'equipmentDetail.description.label'}>
                        Item Description
                     </Typography>
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.year.label'}>
                           Year
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>
                        {imsFormDetails?.ims_forms_data?.groups && imsFormDetails?.ims_forms_data?.groups.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[0]?.component_response ? 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[0]?.component_response : 
                     "-----"}
                        </Typography>
                     </Grid>
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.make.label'}>
                           Make
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>
                        {imsFormDetails?.ims_forms_data?.groups && imsFormDetails?.ims_forms_data?.groups.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[1]?.component_response ? 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[1]?.component_response : 
                     "-----"}
                           </Typography>
                     </Grid>
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.model.label'}>
                           Model
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>
                        {imsFormDetails?.ims_forms_data?.groups && imsFormDetails?.ims_forms_data?.groups.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[2]?.component_response ? 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[2]?.component_response : 
                     "-----"}
                        </Typography>
                     </Grid>
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.type.label'}>
                           Item Type
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>
                        {imsFormDetails?.ims_forms_data?.groups && imsFormDetails?.ims_forms_data?.groups.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components.length > 0 && 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[3]?.component_response ? 
                     imsFormDetails?.ims_forms_data?.groups[0]?.components[3]?.component_response : 
                     "-----"}
                           </Typography>
                     </Grid>
                  </Grid>
                  {hasConditionalProperty && <Divider className={classes.divider}/>}
                  {item.serial && item.serial !== 'unknown' && (
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.serial.label'}>
                           Serial: N/A
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>{item.serial}</Typography>
                     </Grid>
                  )}
                  {item.vin && item.vin !== 'unknown' && (
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.vin.label'}>
                           VIN: N/A
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>{item.vin}</Typography>
                     </Grid>
                  )}
                  {item.unique_id && item.unique_id !== 'unknown' && (
                      <Grid container className={classes.propertiesStyle} spacing={0}>
                         <Typography className={classes.titleStyle} id={'equipmentDetail.uniqueId.label'}>
                            Unique ID #: N/A
                         </Typography>
                         <Typography className={classes.propertyNumberStyle}>{item.unique_id}</Typography>
                      </Grid>
                  )}
                  {item.personal_id && item.personal_id !== 'unknown' && (
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.inventoryId.label'}>
                           Inventory ID: N/A
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>{item.personal_id}</Typography>
                     </Grid>
                  )}
                  {evalItem.sticker_id && evalItem.sticker_id !== 'unknown' && (
                     <Grid container className={classes.propertiesStyle} spacing={0}>
                        <Typography className={classes.titleStyle} id={'equipmentDetail.stickerId.label'}>
                           Sticker ID: N/A
                        </Typography>
                        <Typography className={classes.propertyNumberStyle}>{evalItem.sticker_id}</Typography>
                     </Grid>
                  )}
                  {meters.map((meter, index) => (
                        <div key={index + (meter.name || meter.units)}>
                           {meter.value && meter.value !== '0' ? (
                              <Grid item className={classes.propertiesStyle}>
                                 <Typography className={classes.titleStyle}>
                                    {meter.name || meter.units}
                                 </Typography>
                                 <Typography className={classes.propertyNumberStyle}>{numberFormatter('#,###.',
                                    meter.value)}</Typography>
                              </Grid>
                           ) : undefined}
                        </div>
                     )
                  )}
                  <Grid container className={`${classes.smallPropertyTop} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle}
                                 id={'equipmentDetail.features.label'}>
                        Features
                     </Typography>
                     {item.features ? item.features.map((feature, index) => (
                           <Typography key={index + 'feature'} hasLineBreaks>
                              <pre className={classes.featuresInnerStyle}>{feature}</pre>
                           </Typography>
                        )
                     ) : (
                        <Typography className='accent-text-small' id={'equipmentDetail.none.label'}>None</Typography>
                     )}
                  </Grid>
                  <Grid container className={`${classes.smallProperty} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle}
                                 id={'equipmentDetail.problems.label'}>
                        Problems
                     </Typography>
                     {item.problems ? item.problems.map((problems, index) => (
                           <Typography key={index + 'problem'}
                                       className={classes.largePropertyStyle}>{problems}</Typography>
                        )
                     ) : (
                        <Typography className='accent-text-small' id={'equipmentDetail.none.label'}>None</Typography>
                     )}
                  </Grid>
                  <Grid container className={`${classes.smallProperty} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle}
                                 id={'equipmentDetail.notes.label'}>
                        Notes
                     </Typography>
                     {notes !== '' ? (
                        <Typography className={classes.notesStyle} hasLineBreaks>
                           <pre className={classes.notesInnerStyle} dangerouslySetInnerHTML={{__html: notes}}/>
                        </Typography>
                     ) : (
                        <Typography className='accent-text-small' id={'equipmentDetail.none.label'}>None</Typography>
                     )}
                  </Grid>
                  <Grid container className={`${classes.smallProperty} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     <Typography className={classes.titleStyle} style={{marginBottom: 8}}
                                 id={'equipmentDetail.attachments.label'}/>
                     {(evalItem && evalItem.attachment_data && evalItem.attachment_data.length > 0) ?
                        evalItem.attachment_data.map((attachment, index) => (
                           <Link key={'attachment '+index} href={attachment.url} rel='noreferrer' target='_blank' style={{marginBottom: 8}}>
                              <Grid container alignItems={'center'} wrap={'nowrap'}>
                                 <img alt='thumbnail of asset' src={attachment.thumbnail} height={30}
                                      style={{marginRight: 8}}/>
                                 {attachment.original_file_name}
                              </Grid>
                           </Link>
                        )) : (
                           <Typography className='accent-text-small'
                                       id={'equipmentDetail.none.label'}>None</Typography>
                        )}
                  </Grid>
                  {/*Links*/}
                  <Grid container className={`${classes.smallProperty} ${classes.gridItemStyle}`}
                        direction={'column'} spacing={0} wrap={'nowrap'}>
                     {(!!editLink || isNewLink) && <EditLinkDialog asset={evalItem} link={editLink} onClose={handleLinkClose} onSubmit={handleLinkSubmit} open={(!!editLink || isNewLink)}/>}
                     <Grid item container direction={'row'} alignItems={'center'} spacing={1}>
                        <Grid item>
                           <Typography className={classes.titleStyle} id={'equipmentDetail.links.label'}/>
                        </Grid>
                        <Grid item>
                           <IconButton size={'small'} onClick={() => setIsNewLink(true)}>
                              <AddCircleOutline/>
                           </IconButton>
                        </Grid>
                     </Grid>
                     {links.length > 0 ? evalItem.links.map((link, index) => (
                           <Grid container direction={'column'}>
                              <Grid item>
                                 <Typography key={index + 'link'} className={classes.largePropertyStyle}>
                                    {link.link_type}
                                 </Typography>
                              </Grid>
                              <Grid item container direction={'row'} alignItems={'center'}  style={{marginBottom: 8}}>
                                 <Link href={link.url} rel='noreferrer' target='_blank'>
                                    {link.title || link.url}
                                 </Link>
                                 <IconButton size={'small'} onClick={() => setEditLink(link)} style={{marginLeft: 8, marginRight: 8}}>
                                    <EditIcon style={{fontSize: 18}}/>
                                 </IconButton>
                                 <ConfirmButton
                                    onConfirm={deleteLink(link)}
                                    titleKey={'link.delete.confirm.title'}
                                    messageKey={'link.delete.confirm'}
                                    values={{link: link.title}}
                                    component={IconButton}
                                    buttonLabelKey={'delete.button'}
                                    submitKey={'delete.button'}
                                    size={'small'}
                                    disableRipple
                                    confirmProps={{submitColorStyle: 'destroy-button', cancelColorStyle: 'secondary-color'}}
                                    buttonTypographyProps={{color: 'textSecondary', style:{textDecoration: 'underline'}}}
                                 >
                                    <Clear style={{fontSize: 20}}/>
                                 </ConfirmButton>
                              </Grid>
                           </Grid>
                        )
                     ) : (
                        <Typography className='accent-text-small' id={'equipmentDetail.none.label'}/>
                     )}
                  </Grid>
               </Grid>
               {showEditButton && (
                  <Grid container className={classes.smallPropertyBottom} direction={'column'}>
                     <Typography className={classes.updatedByStyle}
                                 id={'equipmentDetail.updatedBy.label'} values={{
                        name: getUpdatedBy(item.updated_by),
                        time: getUpdatedTime(item.updated)
                     }}/>
                  </Grid>
               )}
            </Grid>
         </Grid>
      </div>
   );
}

export default injectIntl(AssetDetail);