import Menu from '@material-ui/core/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-table/react-table.css';
import { STATUS_ORDER } from '../../../Constants';
import { FTCheckbox } from '../../FTCheckbox';
import { List, Divider } from '@mui/material';

const styles = theme => ({
   dot: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
   },
   capture_in_progress: {
      backgroundColor: "#F8CE1C"
    },
    capture_complete: {
      backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
    },
    sent_to_ims: {
      backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
    },
    final_review: {
      backgroundColor: "#742682"
    },
   finalized: {
      backgroundColor: theme.palette.status.finalized, //'#406370',
   },
   archived: {
      backgroundColor: theme.palette.status.archived, //'#D8D8D8',
   },
   menuText: {
      display: 'inline-block',
   },
   image: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(2) - 4,
   },
   clear: {
      borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
      backgroundColor: theme.palette.environment.light.level1.base,
      marginBottom: theme.spacing(-1),
   },
   statusCheckbox: {
      color: "#4f2682 !important",
   },
   mouseTracker: {
      '&:focus': {
         outline: 'none',
      }
   },
});

/**
 * Component to show the workflow status filter menu. (Not the workflow select menu).
 *
 * Last Reviewed: 11/12/18
 */
class StatusFilterMenu extends Component {

   static propTypes = {
      classes: PropTypes.object,          // The styles for the component.
      isOpen: PropTypes.bool,             // Indicates if the menu is open or closed.
      anchorEl: PropTypes.any,            // The anchor element for the menu (See Menu prop).
      onClose: PropTypes.func.isRequired, // The callback when the menu is closed.
      filters: PropTypes.array,           // The list of initial filters.
   };

   static defaultProps = {
      isOpen: false,
      filters: STATUS_ORDER,
   };

   constructor(props) {
      super(props);

      this.state = {
         capture_in_progress: props.filters.indexOf('capture_in_progress') >= 0,
         capture_complete: props.filters.indexOf('capture_complete') >= 0,
         sent_to_ims: props.filters.indexOf('sent_to_ims') >= 0,
         final_review: props.filters.indexOf('final_review') >= 0,
         finalized: props.filters.indexOf('finalized') >= 0,
         archived: props.filters.indexOf('archived') >= 0,
         selectAll: props.filters.length >= STATUS_ORDER.length,
      };
   }

   /**
    * Handle the filter menu close.
    */
   handleFilterClose = () => {
      let enabledFilters = this.getEnabledFilters();
      this.props.onClose(enabledFilters);
   };

   /**
    * Get the enabled filters from the state.
    *
    * @return {string[]} The array of enabled filters.
    */
   getEnabledFilters = () => {
      return STATUS_ORDER.filter(status => this.state[ status ] === true);
   };

   /**
    * When the user clicks the status menu item.
    * @param name The name of the status.
    * @return {Function} The callback for the onClick for the named status.
    */
   onClick = (name) => {
      const countEnabled = this.getEnabledFilters().length;
      const checked = !this.state[ name ];
      if (checked || countEnabled > 1) {
         this.setState({ [ name ]: checked, selectAll: checked && countEnabled >= (STATUS_ORDER.length - 1) });
      }
   };

   /**
    * Select all the filters.
    */
   selectAllFilters = (event) => {
      // event.stopPropagation();
      // event.preventDefault();

      let state = this.state;

      state.selectAll = true;

      for (const status of STATUS_ORDER) {
         state[ status ] = true;
      }
      this.setState(state);
   };

   render() {
      const { isOpen } = this.props;

      return (
         <Menu
               id='status-menu-id' 
               anchorEl={isOpen ? document.getElementById('status-filter-button') : null}
               style={{ userSelect: 'none' }}
               open={isOpen}
               onClose={this.handleFilterClose}
               anchorOrigin={{
                  vertical: 'bottom', // Use 'top', 'center', or 'bottom' as a valid value
                  horizontal: 'left', 
               }}
               transformOrigin={{
                  vertical: 'top', 
                  horizontal: 'left',
               }}
               getContentAnchorEl={null}
            >

            <List>
               {STATUS_ORDER.map(status => {
                  let name  = status.split('_')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ');

                  return (
                  <FTCheckbox 
                     key={status}
                     value={name}
                     onClick={()=>this.onClick(status)}
                     checked={this.state[ status ]}
                     status={status}
                  />
               )})}
            </List>
            <Divider />
            <FTCheckbox 
               key={'Select All'}
               value={'Select All'}
               onClick={this.selectAllFilters}
               checked={this.state.selectAll}
               disabled={this.getEnabledFilters().length >= STATUS_ORDER.length}
            />
         </Menu>
      );
   }
}

export default withStyles(styles)(StatusFilterMenu);

