import {Checkbox} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {withRouter} from 'react-router-dom';
import Typography from '../../../fhg/components/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {THUMBNAIL_SIZE, PLACEHOLDER_IMAGE, IMAGE_VIEW_KEYS, IMAGE_VIEW_CONVERT} from '../../../Constants';
import {CheckboxBackground, CheckboxSelected} from '../../../Icons';
import PhotoMoreMenu from '../components/PhotoMoreMenu';
import { Modal} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



const checkboxStyles = theme => ({
   root: {
      color: `${theme.palette.environment.light.divider} !important`,
      '&$checked': {
         color: `${theme.palette.brand.blue} !important`, //#489AC7
      },
   },
   checked: {},
});

function CustomCheckbox4(props) {
   return (
      <Checkbox color='default' checkedIcon={<CheckboxSelected/>}
                icon={<CheckboxBackground stroke={props.theme.palette.environment.light.divider}/>} {...props} />
   );
}

export const CustomCheckboxSolid = withStyles(checkboxStyles, {withTheme: true})(CustomCheckbox4);

const styles = theme => ({
   verticalThumbs: {
      height: '100%',
      width: THUMBNAIL_SIZE + 8,
      display: 'block',
      padding: '0 4px',
      marginLeft: '4px !important',
      marginTop: '0px !important',
      borderLeft: `1px solid ${theme.palette.divider}`,
   },
   horizontalThumbs: {
      height: THUMBNAIL_SIZE,
      width: '100%',
      flexWrap: 'nowrap',
      flex: '0 0 auto',
      paddingTop: 4,
      marginLeft: '0px !important',
      marginTop: '4px !important',
      marginBottom: '7px !important',
      borderTop: `1px solid ${theme.palette.divider}`,
   },
   titleBar: {
      background:
         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   moreTitleBar: {
      background: 'transparent',
      width: '100%',
   },
   titleText: {
      fontSize: `${theme.size.font['item-title']}rem`,
      lineHeight: `${theme.size.lineheight['item-title']}rem`,
      letterSpacing: `${theme.size.letterspacing['item-title']}rem`,
   },
   actionIconStyle: {
      width: '100%',
   },
   titleWrap: {
      display: 'none',
   },
   imageType: {
      background:
         'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      bottom: 0,
      position: 'absolute',
      color: 'white',
      textAlign: 'center',
      width: '100%',
      paddingBottom: 4,
      marginLeft: 4,
   }
});

class ThumbnailList extends PureComponent {

   static propTypes = {
      classes: PropTypes.any,
      thumbColumns: PropTypes.number,
      images: PropTypes.array,
      selectedImage: PropTypes.object,
      onDeletePhoto: PropTypes.func,
      onRefresh: PropTypes.func,
      onSelect: PropTypes.func,
      onSelectThumbnail: PropTypes.func,
      onMovePhoto: PropTypes.func,
      selected: PropTypes.array,
      onSortAllPhotos: PropTypes.func,
      evalItem: PropTypes.object,  
   };

   state = {
      currentImages : this.props.images,
      currentVideos : this.props.evalItem.attachment_data || [],
      isVideoModalOpen: false,
      selectedVideo: null,
   };

   componentDidMount = () => {
   this.imageShuffle()
   console.log(" Images Keys ==============>>>>>>>>", IMAGE_VIEW_KEYS);
   console.log(" Images Convert ==============>>>>>>>>", IMAGE_VIEW_CONVERT);
   }

imageShuffle = () => {
   // Clone the currentImages array
   const clonedImages = [...this.state.currentImages];
 
   // Custom sorting function to place "AMKT" first
   const customSort = (a, b) => {
     if (a.image_view === 'AMKT') {
       return -1; // "AMKT" comes first
     }
     if (b.image_view === 'AMKT') {
       return 1; // "AMKT" comes first
     }
     return 0; // Keep the original order for other items
   };
 
   // Sort the clonedImages array using the customSort function
   clonedImages.sort(customSort);
 
   // Update the component state with the sorted array
   this.setState({ currentImages: clonedImages });
   //console.log("IMAGES===========================>>>>>>>" ,this.state.currentImages);
 }

   handleSelect = index => (event) => {
      const {onSelectThumbnail, selected} = this.props;
      event.stopPropagation();
      event.preventDefault();

      const selectedArray = selected.slice(0);
      selectedArray[index] = event.target.checked;
      onSelectThumbnail && onSelectThumbnail(selectedArray);
   };

   handleVideoThumbnailClick = (video) => {
      this.setState({
        isVideoModalOpen: true,
        selectedVideo: video,
      });
    };
  
    handleVideoModalClose = () => {
      this.setState({
        isVideoModalOpen: false,
        selectedVideo: null,
      });
    };

   render() {
      const {
        classes, location, thumbColumns, selected, selectedImage, onDeletePhoto, onSelect, onRefresh, evalItem,
        onMovePhoto, onSortAllPhotos
      } = this.props;
    
      const { currentImages, currentVideos } = this.state;
      const isEdit = get(location, 'state.isEdit', false);
    
      return (
         <>
        <GridList
          id='thumbnailListId'
          className={classes.horizontalThumbs}
          cols={5}
          cellHeight={THUMBNAIL_SIZE}
        >
          {(currentImages.concat(currentVideos)).map((item, index) => {
            const isVideo = Boolean(item.url);
    
            const thumbnail = isVideo ? get(item, 'url', PLACEHOLDER_IMAGE) : get(item, 'sizes.thumbnail', PLACEHOLDER_IMAGE);

            const imageViewKey = IMAGE_VIEW_KEYS[item.image_view] ||
              IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[item.image_view]] ||
              item.image_view;
    
            return (
              <GridListTile
              key={index}
              className={classNames({ ['selected-thumbnail']: selectedImage === item }, classes.zoomThumb)}
              style={{ position: 'relative' }}
              onClick={() => (isVideo ? this.handleVideoThumbnailClick(item) : onSelect(item))}
              >
                 {isVideo ? (
                              <video controls width="200" height="150" poster={thumbnail} >
                                 <source src={thumbnail} type="video/mp4" />
                                 Your browser does not support the video tag.
                              </video>
                           ) : (
                              <img
                                 src={thumbnail}
                                 alt=''
                                 onError={(e) => {
                                 e.target.src = PLACEHOLDER_IMAGE;
                                 }}
                                 onClick={ onSelect(item)}
                              />
                           )}
    
                <Typography
                  id={
                    isVideo
                      ? IMAGE_VIEW_KEYS.VIDEO
                      : IMAGE_VIEW_KEYS[item.image_view] ||
                        IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[item.image_view]] ||
                        item.image_view ||
                        'photoDetail.changeCategory.additional'
                  }
                  className={classes.imageType}
                />
    
                {isEdit ? (
                  <GridListTileBar
                    classes={{ title: classes.titleText }}
                    title={
                      <Typography
                        id={
                          isVideo
                            ? IMAGE_VIEW_KEYS.VIDEO
                            : IMAGE_VIEW_KEYS[item.image_view] ||
                              IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[item.image_view]] ||
                              item.image_view ||
                              'photoDetail.changeCategory.additional'
                        }
                      />
                    }
                    titlePosition='top'
                    className={classes.titleBar}
                  />
                ) : !item.__isPlaceholderImage && imageViewKey !== 'photoDetail.changeCategory.unknown' && (
                  <GridListTileBar
                    titlePosition='top'
                    actionPosition='left'
                    className={classes.moreTitleBar}
                    classes={{ actionIcon: classes.actionIconStyle, titleWrap: classes.titleWrap }}
                    actionIcon={
                      (isVideo && (IMAGE_VIEW_KEYS[item.image_view] === ('UNKOWN' || "ATTACHMENT"))) ? ( 
                        null
                        ) : (
                           <Grid container direction={'row'} justify={'space-between'}>
                        <CustomCheckboxSolid
                          key={selected[index] ? index + 'select' : index + 'not selected'}
                          checked={selected[index]}
                          style={{ zIndex: 1002 }}
                          onClick={this.handleSelect(index)}
                          icon={<CheckboxBackground />}
                        />
                        <PhotoMoreMenu
                          image={item}
                          onDeletePhoto={onDeletePhoto(item)}
                          onMovePhoto={onMovePhoto(item)}
                          onRefresh={onRefresh}
                          isFirst={index === 0}
                          isLast={index >= currentImages.length + currentVideos.length - 1}
                          onSortAllPhotos={onSortAllPhotos}
                          evalItem={evalItem}
                          index={index}
                        />
                      </Grid>
                        )
                    }
                  />
                )}
              </GridListTile>
            );
          })}
        </GridList>
        <Modal open={this.state.isVideoModalOpen} onClose={this.handleVideoModalClose}>
            <div style={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               maxWidth: '80vw',
               maxHeight: '80vh',
               width: 'auto',
               height: '100%',
               overflow: 'auto',
            }}>
               {this.state.selectedVideo && (
                  <video controls style={{ width: '100%', height: '100%' }}>
                  <source src={this.state.selectedVideo.url} type="video/mp4" />
                  Your browser does not support the video tag.
                  </video>
               )}

               {/* Close Button */}
               <IconButton
                  aria-label="close"
                  style={{ position: 'fixed', top: '8px', right: '8px', color: 'white' }}
                  onClick={this.handleVideoModalClose}
               >
                  <CloseIcon />
               </IconButton>
            </div>
            </Modal>

        </>
      );
    }
    
 }

export default withRouter(withStyles(styles)(ThumbnailList));


