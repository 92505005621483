import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {filter, isArray} from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {useState, useMemo, useEffect} from 'react';
import {useHistory, useLocation, Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {
   PLACEHOLDER_IMAGE, THUMBNAIL_SIZE, GET_ASSET_SEARCH, GET_CATALOG_SEARCH, DATE_FORMAT,
   HEADER_ICON_WIDTH_EVAL_TABLE, HEADER_HEIGHT_EVAL_TABLE, DOWN_ARROW, UP_ARROW
} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../../fhg/components/DisplayError';
import Grid from '../../../fhg/components/Grid';
import FHGTypography from '../../../fhg/components/Typography';
import Typography from '../../../fhg/components/Typography';
import useMessage from '../../../fhg/utils/useMessage';
import {getProfileThumbnail, formatMessage, sortDate, sortStatus} from '../../../fhg/utils/Utils';
import {isFeatureEnabled, CUSTOMER_ENV} from '../../../Utils/ServerUtil';
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer';
import AssetTable from '../list/AssetTable';
import {ImageInfo} from '../list/EquipmentList';
import EquipmentListItemDescription from '../list/EquipmentListItemDescription';
import EvalDashboardTable from './EvalDashboardTable';
import {useIntl} from 'react-intl';
import eventCapture from '../../../eventList';
import { posthogCapture } from '../../../PostHogFunction';

const useStyles = makeStyles(theme => ({
   tableStyle: {
      '& .rt-thead': {
         flex: '0 0 auto',
         backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
         height: 0,
         padding: 0,
      },
      '& .rt-noData': {
         backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
      },
      '& .rt-thead.-header': {
         borderBottom: 'none !important',
      }
   },
   image: {
      maxHeight: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      maxWidth: THUMBNAIL_SIZE + theme.spacing(1) + 2,
      position: 'relative',
      cursor: 'pointer',
   },
   chipStyle: {
      composes: 'default-item-title',
      zIndex: 1001,
      position: 'absolute',
      right: 2,
      bottom: 2,
      backgroundColor: 'rgba(255,255,255,0.85)',
      height: 14,
      width: 'auto',
      borderRadius: 2,
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
   },
   formStyle: {
      maxHeight: '100%',
      padding: theme.spacing(0),
   },
   contentStyle: {
      display: 'flex',
   },
   linkStyle: {
      marginTop: -8,
      height: 'calc(100% + 16px)',
      background: 'lightgrey',
      textDecoration: 'none',
   },
   linkStyleTwo: {
      marginTop: -8,
      height: 'calc(100% + 16px)',
      background: 'lightgrey',
     color: 'blue !important',
   },
   columnTitle: {
      paddingTop: 3,
      color: theme.palette.content.nonessential, //'rgba(66,51,19,0.4)',
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: '17px',
   },
   dot: {
      height: 8,
      width: 8,
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
   },
   capture_in_progress: {
      backgroundColor: "#F8CE1C"
    },
    capture_complete: {
      backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
    },
    sent_to_ims: {
      backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
    },
    final_review: {
      backgroundColor: "#742682"
    },
   finalized: {
      backgroundColor: theme.palette.status.finalized, //'#406370',
   },
   archived: {
      backgroundColor: theme.palette.status.archived, //'#D8D8D8',
   },
   table: {
      maxHeight: `100%`,
      width: '100%',
      border: 'none',
      userSelect: 'none',
      overflow: 'auto',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         maxHeight: '100%',
         overflow: 'hidden',
         position: 'relative',
      },
      '@media print': {
         display: 'table',
      },
      '& .-sort-desc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-asc .offset.sortContent::after': {
         transform: 'translateY(-2px)',
      },
      '& .-sort-desc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${DOWN_ARROW}) no-repeat`,
            mask: `url(${DOWN_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${DOWN_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
      '& .-sort-asc .sortContent::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         '@supports not (-ms-high-contrast: none)': {
            backgroundColor: theme.palette.button.standard.secondary,
            '-webkit-mask': `url(${UP_ARROW}) no-repeat`,
            mask: `url(${UP_ARROW}) no-repeat`,
         },
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.5,
            background: `url(${UP_ARROW}) no-repeat`,
         },
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
   },
   emptyHeight: {
      minHeight: 400,
      height: `calc(100% - ${theme.spacing(4) + 12}px)`,
   },
   progress: {
      position: 'absolute',
      marginLeft: '47%',
      top: 40,
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
         backgroundColor: `${theme.palette.action.selected} !important`,
      },
   },
   menuText: {
      color: theme.palette.text.secondary,
      display: 'inline-block',
   },
   notSort: {
      '&.offset.sortContent:hover::after': {
         transform: 'translateY(-2px)',
      },
      '&.sortContent:hover::after': {
         content: '""',
         width: HEADER_ICON_WIDTH_EVAL_TABLE,
         height: HEADER_HEIGHT_EVAL_TABLE - 1,
         filter: 'opacity(30%)',
         '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
            opacity: 0.3,
         },
         background: `url(${UP_ARROW}) no-repeat`,
         display: 'inline-block',
         verticalAlign: 'middle',
         transform: 'translateY(-4px)',
      },
   },
   defaultTextStyle: {
      fontSize: `${theme.size.font.text}rem !important`,
      color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
   },
   defaultTextStyleTwo: {
      fontSize: `${theme.size.font.text}rem !important`,
      color: "blue !important", 
    },

}), {name: 'SearchPastCatalogsStyles'});

SearchPastCatalogs.propTypes = {
   open: PropTypes.bool,
   onClose: PropTypes.func,
};

/**
 * Component to search past catalog for assets.
 *
 * @param open True if the dialog should be open.
 * @param children The enclosed children components.
 * @param onClose Callback when the dialog closed.
 * @return {JSX.Element}
 * @constructor
 */
export default function SearchPastCatalogs({open, onClose}) {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();
   const intl = useIntl();
   const noAssetsFoundMessage = useMessage('search.noAssetsFound.text');
   const noSearchMessage = useMessage('search.noSearch.text');

   const [list, setList] = useState();
   const searchParams = parse(location.search, {parseBooleans: false, parseNumbers: false});
   const searchText = searchParams && searchParams.search && searchParams.search.trim();

   const [submitSearch, {error, data = []}] = useLazyRequestForServer();

   const [isLoading, setIsLoading] = useState(false);

   const [sortKey, setSortKey] = useState();
   const [sort, setSort] = useState();


   /**
    * Set the list from the server data with the found assets.
    */
   useEffect(() => {
      setList(isArray(data) ? data : undefined);
   }, [data]);

   /**
    * Set loading to false after the list has been set.
    */
   useEffect(() => {
      if (list) {
         setIsLoading(false);
      }
   }, [list]);

   /**
    * A new search is sent to the server when the searchText changes. The URL is updated with the new search text.
    */
   useEffect(() => {
      try {
         if (searchText && searchText !== '') {
            setIsLoading(true);
            submitSearch(searchParams.type === 'Asset' ? GET_ASSET_SEARCH.format({searchText}) :
               GET_CATALOG_SEARCH.format({searchText}));
         } else {
            setList(undefined);
         }
      } catch (error) {
         //Intentionally ignored. Needed to avoid false error indicating an uncaught exception.
      }
   }, [searchText, history, location.pathname, submitSearch]);

   const getCatalogDate = (row) => {
      if (row.created) {
         return moment(row.created).format(DATE_FORMAT);
      } else {
         return EvalDashboardTable.DATE_MISSING;
      }
   }

   const getCatalogUpdate = (row) => {
      if (row.updated) {
         return moment(row.updated).format(DATE_FORMAT);
      } else {
         return EvalDashboardTable.DATE_MISSING;
      }
   }

   const getCatalogStatusCell = row => {
      const status = get(row, 'original.workflow_status');

      if (!isFeatureEnabled(CUSTOMER_ENV)) {
         return (
            <Grid container direction={'row'} alignItems={'center'} style={{height: '100%'}}>
               <span className={classNames(classes.dot, classes[status])}/>
               <Typography className={classes.menuText} color='inherit' variant='body1' id={status}/>
            </Grid>
         );
      } else {
         return (
            <Typography
               id={status !== 'capture_in_progress' ? 'customerInventory.sentToPurpleWave.status' :
                  'customerInventory.notSentToPurpleWave.status'}/>
         );
      }
   };

   /**
    * Get the table columns for the evaluations table. Used for the evaluations table, but also used for the filtering
    * and searches to access the column data.
    *
    * @param defaultSort The sort to initialize the table with. Used only in the constructor before the state is set.
    * @return {*[]} The array of evaluation table columns.
    */
   const catalogColumns = useMemo(() => {
      const sortColumnId = get(sort, '[0].id');

      return [
         {
            id: 'evalUser',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalUser'})} id={'evalDashboard.primaryContact.column'}>
                  Primary Contact
               </Typography>
            ),
            accessor: 'primary_contact',
            minWidth: 120,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalOwner',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalOwner'})} id={'evalDashboard.equipmentOwner.column'}/>
            ),
            accessor: 'owner_name',
            minWidth: 150,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, 
         {
            id: 'evalOwnerID',
            Header: (
               <Typography className={classNames('sortContent', classes.columnTitle,
                  {[classes.notSort]: sortColumnId !== 'evalOwner'})} id={"evalDashboard.equipmentOwnerId.column"}/>
            ),
            accessor: "owner_id",
            minWidth: 150,
            Cell: row => <a className={classes.linkStyleTwo} href={`https://cliq.purplewave.com/c/cus.php?c=${row.value}`}
            target="_blank" onClick={(e) => {
              e.stopPropagation()
              posthogCapture(eventCapture.CUSTOMER_NUMBER_CLICKED)
            }}
          >
            {
              <Typography className={classes.defaultTextStyleTwo} noWrap>
                {row.value}
              </Typography>
            }
          </a>,
         },{
            id: 'evalItems',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalItems'})}
                                id={'evalDashboard.item.column'}>Items</Typography>,
            accessor: 'item_count',
            width: 90,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalPhotos',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalPhotos'})}
                                id={'evalDashboard.photos.column'}>Images</Typography>,
            accessor: 'image_count',
            width: 100,
            maxWidth: 100,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalVideos',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalVideos'})}
                                id={'evalDashboard.videos.column'}>Videos</Typography>,
            accessor: 'video_count',
            width: 100,
            maxWidth: 100,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         },{
            id: 'evalDate',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalDate'})}
                                id={'evalDashboard.dateCreated.column'}>Date
               Created</Typography>,
            minWidth: 120,
            accessor: getCatalogDate,
            sortMethod: sortDate,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalUpdate',
            Header: <Typography className={classNames('sortContent', classes.columnTitle,
               {[classes.notSort]: sortColumnId !== 'evalUpdate'})}>Date
               Updated</Typography>,
            minWidth: 120,
            accessor: getCatalogUpdate,
            sortMethod: sortDate,
            Cell: row => <Link className={classes.linkStyle} to={row.original.uri}>{<Typography
               className={classes.defaultTextStyle} noWrap>{row.value}</Typography>}</Link>,
         }, {
            id: 'evalStatus',
            Header: (
               <Grid container
                     className={classNames('offset', 'sortContent',
                        {[classes.notSort]: sortColumnId !== 'evalStatus'})}
                     wrap={'nowrap'} alignItems={'center'}>
                  <Typography className={classes.columnTitle} id={'evalDashboard.status.column'}>Status</Typography>
               </Grid>
            ),
            accessor: 'workflow_status',
            searchText: (value) => formatMessage(intl, value, ''),
            Cell: getCatalogStatusCell,
            sortMethod: sortStatus,
            width: 225,
         },
      ];
   });

   /**
    * Create the columns for the list of found assets.
    */
   const columns = useMemo(() => {
      /**
       * Navigate to the Equipment Detail screen.
       *
       * @param uri The URI of the asset.
       */
      const handleClick = (uri) => (event) => {
         event.preventDefault();
         
         //window?.posthog?.capture(eventCapture.EDITED_PHOTO_VIEW);
         posthogCapture(eventCapture.EDITED_PHOTO_VIEW)

         history.push(uri);
      };

      /**
       * Navigate to the Catalog screen for the asset clicked.
       * @param evalItem The asset whose catalog is selected.
       * @return {function(): void}
       */
      const handleGoToCatalog = evalItem => () => {
        
         // window?.posthog?.capture(eventCapture.EQUIPMENT_LIST_VIEW);
         posthogCapture(eventCapture.EQUIPMENT_LIST_VIEW)

         const evaluationUri = get(evalItem, 'evaluation.uri');
         if (evaluationUri) {
            history.push(evaluationUri);
         } else {
            console.log('URI not found for catalog', evalItem);
         }
      };

      return [
         {
            id: 'image',
            width: THUMBNAIL_SIZE + 23,
            sortable: false,
            Cell: row => {
               let image = getProfileThumbnail(row.original);
               const imageCount = filter(get(row.original, 'image_data', []), {deleted: false}).length;
               return <>
                  <Link className={classes.linkStyle} to={row.original.uri}>
                     <ImageInfo key={row.index + 'image'} classes={classes} src={image}
                                onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}
                                imageCount={imageCount} onClick={handleClick(row.original.uri)}/>
                  </Link>
                  <Grid container justifyContent={'center'} alignItems={'center'}
                        style={{height: '100%', paddingTop: 8}}
                  >
                     <Button className={'secondary-minimal-button-small no-print'}
                             variant={'text'}
                             onClick={handleGoToCatalog(row.original)} disableRipple={true}
                     >
                        <Typography color='inherit' variant='button' id={'search.go.button'}/>
                     </Button>
                  </Grid>
               </>
            },
         },
         {
            id: 'info',
            minWidth: 160,
            sortable: false,
            Cell: row => {
               return <EquipmentListItemDescription evalItem={row.original.item} eval={row.original}/>
            },
         },
      ]
   }, [classes, history])

   return (
      <ModalDialog
         open={open}
         onClose={onClose}
         titleKey={searchParams.type === 'Asset' ? 'search.assets.title' : 'search.catalog.title'}
         cancelKey={'close.button'}
         classes={{contentStyle: classes.contentStyle, formStyle: classes.formStyle}}
         maxWidth={searchParams.type === 'Asset' ? 'sm' : 'lg'}
         fullWidth
         isForm={false}
      >
         <DisplayError error={error} errorId='fetch.error'/>
         <Grid item resizable fullWidth isScrollable>
            {searchParams && searchParams.type === 'Catalog' ? (
               <ReactTable defaultPageSize={10000} minRows={1} loading={isLoading}
                           LoadingComponent={() => isLoading && <CircularProgress className={classes.progress}/>}
                           multiSort={false} className={classNames(classes.table, '-highlight', 'eval-table',
                  {[classes.emptyHeight]: !list || list.length <= 0})}
                           data={list}
                           onSortedChange={setSort}
                           showPagination={false}
                           noDataText={isLoading ? '' : (<FHGTypography id={'search.noCatalogsFound.text'}/>)}
                           columns={catalogColumns}
               />

            ) : (
               <AssetTable list={list} loading={isLoading} classes={{equipmentTableStyle: classes.tableStyle}}
                           defaultSort={sortKey}
                           columns={columns}
                           onSortedChange={setSortKey}
                           noDataText={(searchText ? noAssetsFoundMessage : noSearchMessage)}
               />
            )}
         </Grid>
      </ModalDialog>
   );
}
