import {Tooltip} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import {PlaceOutlined} from '@material-ui/icons';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import {castArray, memoize, some, findIndex, sortBy} from 'lodash';
import set from 'lodash/set';
import isNumber from 'lodash/isNumber';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import fill from 'lodash/fill';
import find from 'lodash/find';
import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router-dom';
import 'react-table/react-table.css';
import {
   THUMBNAIL_SIZE, PLACEHOLDER_IMAGE, SCALE_VALUES, IMAGE_STATE_ERROR, IMAGE_STATE_LOADED, IMAGE_STATE_NOT_LOADED,
   ROTATION_SAVE_DELAY, CATALOG_HEADER_HEIGHT, APP_BAR_HEIGHT, SECONDARY_HEADER_HEIGHT, IMAGE_VIEW_KEYS,
   IMAGE_VIEW_CONVERT, MAP_URL
} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import Typography from '../../../fhg/components/Typography';
import {getImageObjects, fillProperty} from '../../../fhg/utils/Utils';
import {RotateIcon, ZoomIcon} from '../../../Icons';
import {saveRotation, deletePhotos, updatePhotos} from '../../../Utils/SubmitUtil';
import ErrorSnackbar from '../components/ErrorSnackbar';
import ImageComponent from '../components/ImageComponent';
import PhotoCategorySelect from '../components/PhotoCategorySelect';
import {MOVE_NEXT, MOVE_PREVIOUS, MOVE_FIRST, MOVE_LAST} from '../components/PhotoMoreMenu';
import '../dashboard/EvalDashboardTable';
import {SuccessCheck} from '../list/UploadPhotoSummary2';
import PhotoHeader from './PhotoHeader';
import ThumbnailList from './ThumbnailList';
import eventCapture from '../../../eventList';
import { posthogCapture } from '../../../PostHogFunction';
import IMSForm from './IMSForm';
import withSelectedCategory from '../list/withSelectedCategory';
import withIsTabClicked from './WithIsTabClicked';
import { IsTabClickedContext } from './AssetPropertiesDrawer';

/**
 * Component to show the equipment detail.
 */
const styles = theme => ({
   root: {
      display: 'flex',
      flex: '1 1',
      maxWidth: '100%',
      overflow: 'hidden'
   },
   progress: {
      position: 'fixed',
      top: APP_BAR_HEIGHT + SECONDARY_HEADER_HEIGHT + CATALOG_HEADER_HEIGHT + 10,
      left: 10,
      zIndex: 1001,
   },
   image: {
      '&:after': {
         content: '""',
         position: 'absolute',
         top: 2,
         left: 2,
         right: 2,
         bottom: 2,
         border: `4px solid ${theme.palette.action.selectedDark}`,
         zIndex: 1001,
      },
   },
   titleBar: {
      // background: 'transparent',
      background:
         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   moreTitleBar: {
      background: 'transparent',
   },
   titleText: {
      fontSize: `${theme.size.font['item-title']}rem`,
      lineHeight: `${theme.size.lineheight['item-title']}rem`,
      letterSpacing: `${theme.size.letterspacing['item-title']}rem`,
   },
   placesicon: {
      color : "white !important"
   },

   buttonRotate: {
      composes: 'no-print',
      zIndex: 1001,
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      textTransform: 'none',
      borderRadius: 4,
      padding: `${theme.spacing(0.75)}px !important`,
      color: "#ffff !important",
      backgroundColor: "#4f2682 !important",
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      '&:hover': {
         backgroundColor:  "#7743b8 !important"
      },
      
   },
   chipStyle: {
      composes: 'contrast-text-small',
      // composes: 'no-print',
      zIndex: 1001,
      position: 'absolute',
      right: theme.spacing(2),
      bottom: theme.spacing(1),
      backgroundColor: 'rgba(255,255,255,0.8)',
      height: 29,
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      '& > svg': {
         height: 'calc(100% - 6px)',
         left: 5,
         position: 'relative',
      },
   },
   buttonOutline: {
      zIndex: 1001,
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      textTransform: 'none',
      color: theme.palette.button.standard.secondary,
      backgroundColor: theme.palette.environment.light.level0.base,
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      '&:hover': {
         backgroundColor: theme.palette.environment.light.level0.base,
      },
   },
   fullImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      position: 'relative',
      userSelect: 'none'
   },
   imageFrame: {
      margin: 'auto',
   },
   zoomInButton: {
      color: 'white',
      height: 40,
      borderRadius: '0% 10% 10% 0%;',
      backgroundColor: theme.palette.button.standard.secondary,
      '&:hover': {
         backgroundColor: theme.palette.button.standard.secondary,
      },
   },
   zoomOutButton: {
      color: 'white',
      height: 40,
      borderRadius: '10% 0% 0% 10%;',
      backgroundColor: theme.palette.button.standard.secondary,
      '&:hover': {
         backgroundColor: theme.palette.button.standard.secondary,
      },
   },
   zoomResetButton: {
      color: 'white',
      height: 40,
      borderRadius: '0%',
      backgroundColor: theme.palette.button.standard.secondary,
      '&:hover': {
         backgroundColor: theme.palette.button.standard.secondary,
      },
   },
   zoomPanel: {
      zIndex: 1001,
      position: 'absolute',
      bottom: theme.spacing(1),
      left: 'calc(50% - 100px)',
      whiteSpace: 'nowrap',
   },
   icon: {
      color: 'white',
   },
   imageType: {
      marginLeft: 4,
   }
});

/**
 * The component to show the image and thumbnails in the Equipment Detail screen.
 */
class EquipmentImageDetail extends React.PureComponent {
// Promise for loading an image.
   loading;
   //Indicates if the current preloading has been canceled because a new detail is showing.
   isCancelLoading;
   useAnimation = true;

   static propTypes = {
      classes: PropTypes.any.isRequired,  // The styles for the component.
      match: PropTypes.object.isRequired, // The React Router match parameters.
      evalItem: PropTypes.object,         // The evaluation item.
      allowRotate: PropTypes.bool,        // Indicates that the user can rotate the selected image.
      allowZoom: PropTypes.bool,          // Indicates that the user can zoom the selected image.
      onZoom: PropTypes.func,             // Called when the user zooms the image.
      onRefresh: PropTypes.func,          // Called when catalog has changed (e.g. profile thumbnail).
      isError: PropTypes.bool,            // Indicates if an error occurred so the image isn't shown.
      evaluation: PropTypes.object,       //The evaluation containing the equipment.
   };

   static defaultProps = {
      allowRotate: true,
      allowZoom: true,
      isError: false,
   };

   constructor(props, context) {
      super(props, context);

      const images = getImageObjects(props.evalItem);
      this.state = {
         selectedImage: images[0],
         images,
         imageWidth: '100%',
         imageHeight: '100%',
         isZoomed: get(props, 'location.state.isZoomed', false),
         scale: 1,
         isScaledLocation: false,
         thumbColumns: 4,
         showConfirmDelete: false,
         selected: fill([], false, 0, images.length),
      };
      this.onWheel = this.onWheel.bind(this);

      document.addEventListener('keydown', this.handleKey, false);
   }

   /**
    * Preload the images in the array sequence. The next images is loaded after the previous image finishes.
    */
   preloadSequential = async () => {
      let imageIds = this.state.images.map((image) => {
         return image.image_id;
      });

      for (let imageId of imageIds) {
         // Cancel loading loop if "isCancelLoading".
         if (this.isCancelLoading) {
            return;
         }
         try {
            await this.loadImage(imageId);
         } catch (e) {
            console.log(e);
         }
      }
   };

   /**
    * Load a single image from unloadedOriginals at the index.
    *
    * @param imageId of image to be loaded .
    * @return {*} Promise for the image loading.
    */
   loadImage = (imageId) => {
      if (imageId) {
         const image = find(this.state.images, {image_id: imageId});
         const src = get(image, 'sizes.original');

         if (image.__state === IMAGE_STATE_NOT_LOADED) {
            if (src !== PLACEHOLDER_IMAGE) {
               return new Promise((resolve) => {
                  let newImage = new Image();
                  newImage.src = src;
                  newImage.onload = () => {
                     image.__state = IMAGE_STATE_LOADED;
                     if (imageId === get(this.state, 'selectedImage.image_id')) {
                        this.setState({refresh: Date.now()});
                        this.handleResize();
                     }
                     resolve(image);
                  };
                  newImage.onerror = () => {
                     image.__state = IMAGE_STATE_ERROR;
                     this.onError(image)();
                     resolve(image);
                  };
               });
            } else {
               image.__state = IMAGE_STATE_LOADED;
               this.setState({refresh: Date.now()});
               this.handleResize();
            }
         }
      }
      return Promise.resolve();
   };

   /**
    * Preload the images in images.
    *
    * @param images the array of images to load.
    * @param path The property path to the image URL.
    * @return {*} The promise to load all the images in the images.
    */
   preloadAsync = (images, path) => {
      const promiseArray = [];

      for (const image of images) {
         if (path !== 'sizes.original' || image.__state === IMAGE_STATE_NOT_LOADED) {
            promiseArray.push(new Promise((resolve) => {
               const imageObject = new Image();

               imageObject.onload = () => {
                  if (path === 'sizes.original') {
                     image.__state = IMAGE_STATE_LOADED;
                  }
                  resolve();
               };

               imageObject.onerror = () => {
                  console.log('Could not load image - ' + imageObject.src);
                  if (path === 'sizes.original') {
                     image.__state = IMAGE_STATE_ERROR;
                  }
                  resolve();
               };

               imageObject.src = get(image, path);
            }));
         }
      }

      return Promise.all(promiseArray);
   };

   /**
    * Handle arrow keys for selecting thumbnails.
    * @param event The key event.
    */
   handleKey = (event) => {
      if (!event.defaultPrevented && event.target.type !== 'textarea') {
         const {images, isZoomed, scale} = this.state;
         const selectedIndex = this.getSelectedIndex();
         if ((event.keyCode === 39 || event.keyCode === 40) && event.target.type !== 'text') {
            event.preventDefault();
            this.selectImage((selectedIndex + 1) % images.length)();
            this.scrollIntoView();
         } else if ((event.keyCode === 38 || event.keyCode === 37) && event.target.type !== 'text') {
            event.preventDefault();
            this.selectImage((selectedIndex - 1) >= 0 ? (selectedIndex - 1) : images.length - 1)();
            this.scrollIntoView();
         } else if ((event.key === 'Escape')) {
            if (isZoomed) {
               if (scale !== 1) {
                  this.setState({scale: 1, isScaledLocation: false});
               } else {
                  this.onZoom(false);
               }
            }
         }
      }
   };

   async componentDidMount() {

      console.log("MAIN EVAL ITEM ============>>>>>", this.props.evalItem);
      this.imageGridNode = ReactDOM.findDOMNode(this.imageGridRef);
      this.mainNode = ReactDOM.findDOMNode(this.mainRef);
      this.handleResize();
      window.addEventListener('resize', this.handleDebounceResize);
      try {
         await this.preloadAsync(this.state.images, 'sizes.thumbnail');
         window.setTimeout(() => {
            this.preloadSequential();
         }, 2);
      } catch (e) {
         console.log(e);
      }

      console.log("-----------Test Selected-------------", this.state.selected);
   }

   findChangedImages = (newImages, oldImages) => {
      let hasNewOrUpdated = false;

      for (const newImage of newImages) {
         const foundExisting = find(oldImages, {image_id: newImage.image_id});
         if (foundExisting) {
            if (get(newImage, 'sizes.original') === get(foundExisting, 'sizes.original')) {
               newImage.__state = foundExisting.__state;
               newImage.__rotation = foundExisting.__rotation;
            } else {
               hasNewOrUpdated = true;
            }
         } else {
            hasNewOrUpdated = true;
         }
      }
      return hasNewOrUpdated;
   };

   componentWillReceiveProps(nextProps) {
      const {allowRotate} = nextProps;
      let state = {};

      const images = getImageObjects(nextProps.evalItem);
      const hasNewOrUpdates = this.findChangedImages(images, this.state.images);
      if (hasNewOrUpdates) {

         state = {
            selectedImage: find(images, {image_id: this.state.selectedImage.image_id}) || images[0],
            images,
            imageWidth: '100%',
            imageHeight: '100%',
         };
         this.setState(state, async () => {
            try {
               await this.preloadAsync(images, 'sizes.original');
               this.forceUpdate();
               this.scrollIntoView();
            } catch (e) {
               console.log(e);
            }
         });
      } else if (allowRotate !== this.props.allowRotate) {
         fillProperty(this.state.images, 'rotation', 0);
         state.imageWidth = '100%';
         state.imageHeight = '100%';
         this.setState(state);
      }
   }

   componentWillUnmount() {
      this.isCancelLoading = true;
      document.removeEventListener('keydown', this.handleKey, false);
      window.removeEventListener('resize', this.handleDebounceResize);
      this.handleSaveRotation.flush();
   }

   /**
    * Scroll the selected thumbnail into view.
    */
   scrollIntoView = () => {
      const elements = document.getElementsByClassName('selected-thumbnail');
      let objDiv = document.getElementById('thumbnailListId');
      if (objDiv && elements.length > 0) {
         if (objDiv.clientWidth > objDiv.clientHeight) {
            objDiv.scrollLeft = elements[0].offsetLeft - objDiv.offsetLeft - (objDiv.offsetWidth / 2) +
               (elements[0].offsetWidth / 2);
         } else {
            objDiv.scrollTop = elements[0].offsetTop - objDiv.offsetTop - (objDiv.offsetHeight / 2) +
               (elements[0].offsetHeight / 2);
         }
      }
   };

   /**
    * Handle the window resizing. Move the thumbnails to the right or bottom based on where there is more space based
    * on the image resizing.
    */
   handleResize = () => {
      const {selectedImage} = this.state;
      const mainNode = this.mainNode;
      const mainSlope = mainNode.clientHeight / mainNode.clientWidth;
      const imageNode = document.getElementById('full-hidden-image-id');
      const rotate = selectedImage.__rotation || 0;
      let imageSlope = imageNode ? imageNode.naturalHeight / imageNode.naturalWidth : mainSlope;

      if ((rotate % 180) !== 0) {
         imageSlope = 1 / imageSlope;
      }

      let thumbColumns;
      if (mainSlope < imageSlope) {
         thumbColumns = 1;
      } else {
         thumbColumns = mainNode.clientWidth / THUMBNAIL_SIZE;
      }

      this.setState({thumbColumns}, () => {
         this.setState(this.updateImageSize(rotate));
      });
   };

   /**
    * Handle the window resizing after a debounce.
    */
   handleDebounceResize = debounce(this.handleResize, 250);

   /**
    * Select the image at the index.
    *
    * @param imageOrIndex The image to be selected or the index of th image.
    */
   selectImage = (imageOrIndex) => event => {
      if (!event || (!event.isDefaultPrevented() && !event.isPropagationStopped())) {
         const selectedImage = isNumber(imageOrIndex) ? this.state.images[imageOrIndex] : imageOrIndex;
         this.setState(
            {
               scale: 1, isScaledLocation: false, selectedImage, ...this.updateImageSize(
                  selectedImage && selectedImage.__rotation)
            });
      }
   };

   /**
    * Select the thumbnails with true in the corresponding index in the selected array.
    *
    * @param selected The array of selected values. True will be selected. The index of the item in the array will match
    *    the index of the Thumbnail to be selected.
    */
   handleThumbnailSelect = (selected) => {
      this.setState({selected}, () => this.props.onThumbnailsSelected(some(selected, Boolean)));
   };

   /**
    * Get the index of the selected image.
    *
    * @return {number|*} The index or 0 if not found.
    */
   getSelectedIndex = () => {
      const {selectedImage, images} = this.state;
      if (selectedImage) {
         return images.indexOf(selectedImage);
      }
      return 0;
   };

   /**
    * On image load error replace the image with the placeholder image.
    *
    * @param image The image.
    * @return {Function}
    */
   onError = (image) => () => {
      image.__state = IMAGE_STATE_ERROR;
      console.log('Could not load image - ' + get(image, 'sizes.original'));
   };

   /**
    * When the user clicks the rotate image button, turn image rotation on.
    */
   onRotateClick = () => {
      const {selectedImage} = this.state;
      selectedImage.__rotation = selectedImage.__rotation - 90;

      this.setState({...this.updateImageSize(selectedImage.__rotation)}, this.handleSaveRotation);
   };

   handleSaveRotation = debounce(async () => {
      const {selectedImage} = this.state;
      await saveRotation(selectedImage, selectedImage.__rotation);
      let image = find(this.state.images, {image_id: selectedImage.image_id});
      image.sizes = selectedImage.sizes;
      image = find(this.props.evalItem.image_data, {image_id: selectedImage.image_id});
      image.sizes = selectedImage.sizes;
      await this.preloadAsync([selectedImage], 'sizes.original');
      await this.preloadAsync([selectedImage], 'sizes.thumbnail');
      this.useAnimation = false;
      this.setState({...this.updateImageSize(), refreshThumbnails: Date.now()});
   }, ROTATION_SAVE_DELAY);

   /**
    * When the user clicks the zoom image button, turn on image zooming.
    */
   onZoomClick = () => {
      if (!this.state.isZoomed) {      
         //window?.posthog?.capture(eventCapture.ZOOM);
         posthogCapture(eventCapture.ZOOM)
         
      }
      this.onZoom(!this.state.isZoomed);
   };

   /**
    * When the user is turning on or off zooming, turn zoom on or off and relocate the thumbnails if needed.
    * @param isZoomed
    */
   onZoom = (isZoomed) => {
      this.setState({isZoomed: isZoomed, scale: 1, isScaledLocation: false}, () => {
         this.props.onZoom(isZoomed);
         setTimeout(this.handleResize, 1);
      });
   };

   /**
    * When the user clicks the zoom in button or mouse clicks the image, zoom the image in.
    */
   onZoomInClick = (event, isScaledLocation = false) => {
      const {scale} = this.state;
      let newScale = SCALE_VALUES.find(scaleValue => scaleValue > scale);

      if (!newScale) {
         newScale = SCALE_VALUES[SCALE_VALUES.length - 1];
      }
      this.setState({scale: newScale, isScaledLocation})
   };

   /**
    * When the user clicks the zoom out button or mouse clicks the image with the alt key, zoom the image out.
    */
   onZoomOutClick = (event, isScaledLocation = false) => {
      const {scale} = this.state;
      let currentIndex = findLastIndex(SCALE_VALUES, scaleValue => scaleValue < scale);
      const newScale = SCALE_VALUES[Math.max(currentIndex, 0)];
      this.setState({scale: newScale, isScaledLocation})
   };

   /**
    * When the user clicks the reset zoom button, remove the zoom.
    */
   onZoomResetClick = () => {
      this.setState({scale: 1, isScaledLocation: false})
   };

   /**
    * When the user clicks the image, zoom in or out.
    * @param altKey The altKey from the mouse event to determine zoom in or out.
    */
   onClick = ({altKey}) => {
      if (!altKey) {
         this.onZoomInClick(undefined, true);
      } else {
         this.onZoomOutClick(undefined, true);
      }
   };

   /**
    * When the wheel is used for zooming, zoom the image in or out based on the deltaY.
    * @param event The wheel event.
    */

   onWheel(event) {
      if (this.state.isZoomed) {
         event.stopPropagation();
         event.preventDefault();
   
         // Determine the direction of scrolling (down as negative, up as positive)
         const scrollDirection = -Math.sign(event.deltaY);
   
         // Calculate the new scale based on the scroll direction
         let scale = this.state.scale + scrollDirection * 0.04; 
   
         scale = Math.max(SCALE_VALUES[0], Math.min(SCALE_VALUES[SCALE_VALUES.length - 1], scale));
   
         this.setState({ scale, isScaledLocation: true });
      }
   };   
   

   /**
    * Update the image node size based on the rotation of the image.
    */
   updateImageSize(rotate = 0) {
      const isChangeOrientation = (rotate % 180) !== 0;
      const imageWidth = isChangeOrientation ? this.imageGridNode.clientHeight : this.imageGridNode.clientWidth;
      const imageHeight = isChangeOrientation ? this.imageGridNode.clientWidth : this.imageGridNode.clientHeight;

      return {imageWidth, imageHeight};
   }

   handleConfirmDeletePhoto = image => () => {
      this.setState({showConfirmDelete: true, deletePhoto: image});
   };

   handleCloseConfirmDeletePhoto = () => {
      this.setState({showConfirmDelete: false, deletePhoto: undefined});
   };

   handleCancelSelection = () => {
      const {images} = this.state;
      this.setState({selected: fill([], false, 0, images.length)}, () => this.props.onThumbnailsSelected(false));
   };

   handleCategorizePhoto = () => {
      this.setState({anchorEl: null, showCategorySelect: true});
   };

   closeCategorizePhoto = () => {
      this.setState({showCategorySelect: false});
   };

   onPhotoRecategorizeRefresh = () => {
      const {onRefresh, evalItem} = this.props;

      this.handleCancelSelection();
      onRefresh && onRefresh(evalItem.item, true);
   };

   getSelectedImages = memoize((selected, images) => {
      const selectedImages = [];

      for (let i = 0; i < selected.length; i++) {
         const value = selected[i];
         if (value) {
            selectedImages.push(images[i]);
         }
      }

      return selectedImages;
   });

   handleDeletePhoto = image => async () => {
      const {selected, images} = this.state;
      const {evalItem} = this.props
      const imageArray = image ? castArray(image) : this.getSelectedImages(selected, images);
      const itemId = get(evalItem, 'item_id');
         console.log('DELETE SELECTED------------------->', selected);
         // console.log('DELETE SELECTED 2------------------->', itemId);
      try {
         let stateImages = await deletePhotos(images, imageArray, itemId);
         //window?.posthog?.capture(eventCapture.DELETE_IMAGE);
         posthogCapture(eventCapture.DELETE_IMAGE)


         set(this.props, 'evalItem.image_data', stateImages);

         //If the deleted photo was deleted get the index.
         const selectedIndex = this.getSelectedIndex();

         //If the last photo is deleted, get the placeholder photo.
         if (stateImages.length === 0) {
            stateImages = getImageObjects(this.props.evalItem);
         }

         // Set the list of images without deleted. Select the next image if the selected photo was deleted.
         this.setState({
            images: stateImages,
            showConfirmDelete: false,
            deletePhoto: undefined,
            selected: fill([], false, 0, stateImages.length)
         }, () => {
            this.props.onThumbnailsSelected(false);
            this.selectImage(clamp(selectedIndex, 0, stateImages.length - 1))();
         });
      } catch (error) {
         this.setState({showError: true, ...error});
      }
   };

   /**
    * Find images that don't have an order set and set the order.
    *
    * @param images The images to update.
    * @param lastIndex The last image index to update.
    * @return {[]} The list of images that are being updated.
    */
   updateMissingOrder = (images, lastIndex) => {
      const updatePhotos = [];

      for (let i = 0; i <= lastIndex; i++) {
         const image = images[i];
         if (image.order === undefined || image.order !== i) {
            image.order = i;
            updatePhotos.push(image);
         }
      }
      return updatePhotos;
   }

   /**
    * Add the image to the list of images if it isn't in the list.
    *
    * @param image The image to add.
    * @param images The list of images to add the image to.
    */
   addUniqueImage = (image, images) => {
      const foundIndex = findIndex(images, {original_filename: image.original_filename});
      if (foundIndex < 0) {
         images.push(image);
      }
   }

   /**
    * Move the image first, previous, next, or last in the list of images.
    * @param image The image to move.
    * @param action The type of move (i.e. first, previous, next, or last).
    */
   handleMovePhoto = image => async (action) => {
      const {images} = this.state;

      const itemId = get(this.props, 'evalItem.item.item_id');
      const index = findIndex(images, {original_filename: image.original_filename});
      const lastIndex = action === MOVE_FIRST || action === MOVE_PREVIOUS ? index :
         action === MOVE_LAST ? images.length - 1 : index + 1;
      let updatedImages = this.updateMissingOrder(images, lastIndex);

      this.addUniqueImage(image, updatedImages);

      switch (action) {
         case MOVE_NEXT:
            image.order = index + 1;
            images[index + 1].order = index;
            this.addUniqueImage(images[index + 1], updatedImages);
            break;
         case MOVE_PREVIOUS:
            image.order = index - 1;
            images[index - 1].order = index;
            this.addUniqueImage(images[index - 1], updatedImages);
            break;
         case MOVE_FIRST:
            for (let i = 0; i < index; i++) {
               images[i].order = i + 1;
               this.addUniqueImage(images[i], updatedImages);
            }
            image.order = 0;
            break;
         case MOVE_LAST:
            for (let i = index + 1; i < images.length; i++) {
               images[i].order = i - 1;
               this.addUniqueImage(images[i], updatedImages);
            }
            image.order = images.length - 1;
            break;
         default:
            console.log('Photo move action ' + action + ' is unknown');
            break;
      }
      try {
         await updatePhotos(updatedImages, itemId);
         const sortedImages = sortBy(images, 'order');
         set(this.props, 'evalItem.image_data', sortedImages);

         this.setState({images: sortedImages}, this.handleCancelSelection);
      } catch (error) {
         this.setState({showError: true, ...error});
      }
   };

   /**
    * Sort the images by name.
    */
   handleSortPhotos = async () => {
      const {images} = this.state;
      const itemId = get(this.props, 'evalItem.item.item_id');

      const newImages = sortBy(images, ['original_filename', 'image_id']);
      let updatedImages = this.updateMissingOrder(newImages, newImages.length - 1);
      try {
         await updatePhotos(updatedImages, itemId);
         const sortedImages = sortBy(images, 'order');
         set(this.props, 'evalItem.image_data', sortedImages);

         this.setState({images: sortedImages}, this.handleCancelSelection);
      } catch (error) {
         this.setState({showError: true, ...error});
      }
   };

   handleErrorClose = () => {
      this.setState({showError: false});
   };

   handleRefresh = () => {
      const {evalItem, onRefresh} = this.props;
      onRefresh && onRefresh(evalItem.item, true);
   }

   render() {
      const {classes, isError, evaluation, onBack, evalItem, selectedCategory} = this.props;
      let {allowRotate, allowZoom, isTabClicked, setIsTabClicked} = this.props;
      const {
         // eslint-disable-next-line
         thumbColumns, imageWidth, imageHeight, isZoomed, scale, selectedImage = {}, images, showError,
         showConfirmDelete, deletePhoto, selected = [], showCategorySelect, refreshThumbnails, errorValues,
      } = this.state;
      let selectedImageUrl;
      let imageTop;
      let rotation;
      let longitude;
      let latitude;
      const useAnimation = this.useAnimation;
      this.useAnimation = true;

      if (selectedImage.__state === IMAGE_STATE_LOADED) {
         selectedImageUrl = get(selectedImage, 'sizes.original', PLACEHOLDER_IMAGE);
         latitude = get(selectedImage, 'latitude');
         longitude = get(selectedImage, 'longitude');
         rotation = selectedImage.__rotation || 0;
         if ((rotation % 180) !== 0) {
            imageTop = (imageWidth - imageHeight) / 2;
         }
         allowRotate = allowRotate && selectedImageUrl !== PLACEHOLDER_IMAGE;
      } else {
         selectedImageUrl = get(selectedImage, 'sizes.thumbnail', PLACEHOLDER_IMAGE);
         allowRotate = false;
      }
      allowZoom = allowZoom && selectedImageUrl !== PLACEHOLDER_IMAGE;

      const imageSource = selectedImage.image_source;
      const isVerified = imageSource && imageSource !== 'unknown';
      let verifiedAvatar = isVerified ? <SuccessCheck/> : undefined;
      const hasLocation = latitude && longitude;
      //console.log(selectedCategory, "====================>>>>>>>>>>");
      //console.log(isTabClicked, "====================>>>>>>>>>>");
      const thumbnailsSelected = selected.filter(item => item === true).length;

   return (
         <Fragment>
    {showError && (
        <ErrorSnackbar open={true} errorId={'photoDetail.delete.error'} values={errorValues}
                       enableRefresh={false}
                       onClose={this.handleErrorClose}/>
    )}
    {showConfirmDelete && (
        <ModalDialog open={showConfirmDelete} onClose={this.handleCloseConfirmDeletePhoto}
                     onSubmit={this.handleDeletePhoto(deletePhoto)}
                     messageKey={'photoDetail.delete.confirm'}
                     values={{count: thumbnailsSelected || 1}}
                     titleKey={'photoDetail.delete.confirm.title'}
                     titleValues={{count: thumbnailsSelected || 1}}
                     maxWidth={'xs'}
                     submitKey={'delete.button'} submitColorStyle={'destroy-button'}
                     cancelColorStyle={'secondary-color'}
        />
    )}
    {showCategorySelect && (
        <PhotoCategorySelect onClose={this.closeCategorizePhoto}
                             images={this.getSelectedImages(selected, images)}
                             onRefresh={this.onPhotoRecategorizeRefresh}/>
    )}
    {thumbnailsSelected > 0 &&
    <PhotoHeader onBack={onBack} evaluation={evaluation} thumbnailsSelected={thumbnailsSelected}
                 onDelete={this.handleConfirmDeletePhoto()} onCancel={this.handleCancelSelection}
                 onCategorize={this.handleCategorizePhoto} onSort={this.handleSortPhotos}/>}
    <Grid container className={classes.root} spacing={0} justify={'flex-end'} wrap={'nowrap'} direction={'row'} ref={ref => (this.mainRef = ref)}>
    <Grid item style={{flex: '1 1 100%', overflow: 'hidden', position: 'relative'}} ref={ref => (this.imageGridRef = ref)}>
        {this.props.children}
        {hasLocation && (
            <IconButton name='LocationUrl' target='_blank' href={MAP_URL.format({latitude, longitude})}
                        variant={'text'} className={classes.buttonRotate}
                        style={{right: 114, color: "white"}}
            >
                <Tooltip title={`${latitude}, ${longitude}`}>
                    <PlaceOutlined className={classes.placesicon}/>
                </Tooltip>
            </IconButton>
        )}
        {allowRotate && !isZoomed && (
            <IconButton variant={'text'} className={classes.buttonRotate} style={{right: 64}}
                        onClickCapture={this.onRotateClick}
            >
                <RotateIcon/>
            </IconButton>
        )}
        {allowZoom && !isZoomed && (
            <IconButton variant={'text'} className={classes.buttonRotate}
                        onClickCapture={this.onZoomClick}
            >
                <ZoomIcon/>
            </IconButton>
        )}
        {isZoomed && (
            <IconButton variant={'text'} className={classes.buttonOutline}
                        onClickCapture={this.onZoomClick}
            >
                <CloseOutlinedIcon/>
            </IconButton>
        )}
        {isZoomed && (
            <div className={classes.zoomPanel}>
                <Button variant={'text'} aria-label='Zoom and Pan'
                        className={classes.zoomOutButton}
                        onClickCapture={this.onZoomOutClick}
                        disabled={scale <= SCALE_VALUES[0]}
                >
                    <ZoomOutIcon/>
                </Button>
                <Button variant={'text'} aria-label='Zoom and Pan'
                        className={classes.zoomResetButton}
                        onClickCapture={this.onZoomResetClick}
                    // disabled={disabled}
                >
                    {Number(scale * 100).toFixed(0)}%
                </Button>
                <Button variant={'text'} aria-label='Zoom and Pan' className={classes.zoomInButton}
                        onClickCapture={this.onZoomInClick}
                        disabled={scale >= SCALE_VALUES[SCALE_VALUES.length - 1]}
                >
                    <ZoomInIcon/>
                </Button>
            </div>
        )}
        <Chip className={classes.chipStyle} label={(
            <Typography id={IMAGE_VIEW_KEYS[selectedImage.image_view] ||
            IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[selectedImage.image_view]] || selectedImage.image_view ||
            'photoDetail.changeCategory.additional'} className={classes.imageType}
            />)} avatar={verifiedAvatar}/>
        {!isError && selectedImage.__state === IMAGE_STATE_NOT_LOADED && (
            <CircularProgress className={classes.progress}/>
        )}
        <div className={classes.imageFrame} style={{width: imageWidth, height: imageHeight}} onWheel={this.onWheel}>
            <ImageComponent className={classes.fullImage} src={selectedImageUrl} rotation={rotation}
                            scale={scale} useAnimation={useAnimation}
                            onClick={this.onClick} isScaledLocation={this.state.isScaledLocation}
                            top={imageTop} isZoomed={isZoomed} onError={this.onError(selectedImage)}
            />
        </div>
    </Grid>
    {( isTabClicked ) &&
    <Grid item>
        <IMSForm selectedCategory={selectedCategory} evalItem={evalItem} evaluation={evaluation}/>
    </Grid>
    }

</Grid>
<Grid container>
        <ThumbnailList selectedImage={selectedImage} images={images} selected={selected}
                       onDeletePhoto={this.handleConfirmDeletePhoto}
                       onRefresh={this.handleRefresh}
                       thumbColumns={thumbColumns} onSelect={this.selectImage}
                       onSelectThumbnail={this.handleThumbnailSelect} refresh={refreshThumbnails}
                       onMovePhoto={this.handleMovePhoto} onSortAllPhotos={this.handleSortPhotos}
                       evalItem={evalItem}/>
    </Grid>

</Fragment>

      );
   }
}

export default withRouter(withStyles(styles)(withIsTabClicked(withSelectedCategory(EquipmentImageDetail))));
